// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  Chip,
} from "@mui/material";
import img2 from "../images/nodata-removebg-preview.png";

import * as React from "react";
// import Button from "@mui/material/Button";

// components
import Page from "../components/UI/Page";
// sections
// import LeadCreate from "../components/lead/lead.create";
// ----------------------------------------------------------------------
import DashboardActions from "../components/Dashboard/DashboardActionList";
import DashboardProjects from "../components/Dashboard/DashboardProjectList";
import { useEffect, useState } from "react";
import { getEntityName, getMyActions } from "../services/common.service";
import { getMyProject } from "../services/common.service";
import { AuthContext } from "../context/auth.context";

//-------------------------------------------------------------------------

import { ToastContainer } from "react-toastify";
import { ActionContext } from "../context/action.context";
import { useNavigate } from "react-router-dom";
import { getWatcherLeadList } from "../services/contacts.service";

export default function DashboardApp() {
  const { currentUser, entityName, setEntityName } =
    React.useContext(AuthContext);
  const navigate = useNavigate();
  const { refetchAction } = React.useContext(ActionContext);
  const [leadWatcherList, setLeadWatcherList] = useState([]);
  const theme = useTheme();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const oldDate = new Date();
  oldDate.setDate(oldDate.getDate() - 2);

  const [actionList, setActionList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const message = "Hi good morning";

  // Fetch current loggedin user's Actions

  const fetchActions = () => {
    console.log("first");
    getMyActions()
      .then((res) => {
        if (res.data.status) {
          setActionList([]);
          setActionList(res.data.data);
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Fetch current loggedin user's  Projects

  const fetchProjects = async () => {
    await getMyProject()
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          setProjectList(response.data.data);
          console.log("response", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fetch where i watcher

  const fetchWatcherLeadList = () => {
    getWatcherLeadList()
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          setLeadWatcherList(response.data.data);
          console.log("result getwatcher list", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickWatcherList = (row) => {
    let { contactId, _id } = row;
    console.log("row", row);
    // actionType === constants.CONTACTS
    //   ? navigate(`/contacts/${id}`, { replace: true })
    //   : navigate(`/Lead/${leadId}/${id}`, { replace: true });
    navigate(`/Leads/${_id}/${contactId}`, { replace: true });
  };

  //Entity Name Config

  const responseData = function () {
    getEntityName()
      .then((response) => {
        if (response) {
          const entityId = response?.data.data[0]._id;

          let result = response?.data.data.slice(1);
          result = result.map((item, index) => ({
            ...item,
            id: index + 1,
            toEdit: false,
            entityId: entityId,
          }));

          setEntityName(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (currentUser && currentUser?.role !== "SUPER_ADMIN") {
      fetchWatcherLeadList();
      responseData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && currentUser?.role !== "SUPER_ADMIN") {
      fetchProjects();
      fetchActions();
    }
  }, [refetchAction, currentUser]);
  return (
    <Page title="Dashboard">
      <>
        {currentUser?.role === "SUPER_ADMIN" ? null : (
          <>
            <ToastContainer />
            <Container>
              {/* watcher list */}
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    align="left"
                    variant="h4"
                    style={{ marginBottom: 10 }}
                  >
                    I'm Watching
                  </Typography>
                  {leadWatcherList && (
                    <Card>
                      <CardContent>
                        <Grid container spacing={0}>
                          {leadWatcherList.length ? (
                            <Grid item xs={12}>
                              {leadWatcherList.map((row, index) => (
                                <Chip
                                  label={`${row.summary}`}
                                  style={{ fontWeight: "bold" }}
                                  onClick={() => handleClickWatcherList(row)}
                                  sx={{ marginRight: "10px" }}
                                />
                              ))}
                            </Grid>
                          ) : (
                            <Typography align="center">
                              Currently you aren't watching anything
                            </Typography>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    align="left"
                    variant="h4"
                    style={{ paddingTop: 10, marginBottom: 10 }}
                  >
                    Actions
                  </Typography>
                </Grid>
              </Grid>
            </Container>
            {actionList.length !== 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DashboardActions
                    fetchActions={fetchActions}
                    actionList={actionList}
                    head="Actions Assigned"
                    ondate={new Date().toLocaleDateString("en-IN")}
                  />
                </Grid>
              </Grid>
            ) : null}
            {projectList.length !== 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <DashboardProjects
                    fetchProjects={fetchProjects}
                    projectList={projectList}
                    head="My Projects"
                  />
                </Grid>
              </Grid>
            ) : null}
          </>
        )}
      </>
      {actionList.length === 0 && projectList.length === 0 ? (
        <Grid container spacing={2}>
          <Grid
            className="dashboardGrid"
            display="flex"
            marginTop="150px"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
            minWidth="160vh"
          >
            <img src={img2} width="530px" height="500px" />
            {/* <h2 fontSize="150px" color= "#00ff00">Learn for free</h2> */}
          </Grid>
        </Grid>
      ) : null}
      <div></div>
    </Page>
  );
}
