const apis = require("./api.requests");

const TAG = "report.service";
const path = "/open";

async function getReport(filter, onLoad, page, leadFilterOptions) {
  console.log(filter, onLoad, page, leadFilterOptions);
  // let to = filter.to === "" ? new Date().getTime(): filter.to
  // new Date(new Date().setDate(new Date().getDate() - 30)).getTime()
  return await apis.hitGetApi(
    `${path}/report?from=${onLoad ? filter.from : filter.from}&to=${
      filter.to
    }&page=${page}&status=${leadFilterOptions.status}&country=${
      leadFilterOptions.country
    }&salesOwner=${leadFilterOptions.salesOwner}`
  );
}

async function getInvoiceReport(interval, salesOwners) {
  let { from, to } = interval;
  return await apis.hitGetApi(
    `/common/v1/getInvoiceReport?from=${from}&to=${to}&salesOwners=${[
      ...salesOwners,
    ]}`
  );
}

async function getAccountReport(interval, salesOwners) {
  let { from, to } = interval;
  return await apis.hitGetApi(
    `/common/v1/getAccountsReport?from=${from}&to=${to}&salesOwners=${[
      ...salesOwners,
    ]}`
  );
}

export { getReport, getInvoiceReport, getAccountReport };

// http://localhost:3022/api/open/report?from=1654860085&to=1668919343&page=1
