export const getDateString = (date) => {
  return new Date(date).toString(); // Include time and timezone
};

export const getCurrentDate = () => {
  const date = new Date();
  date.setDate(1);
  return getDateString(date); // Current Date
};

export const getCurrentMonthDate = () => {
  const date = new Date();

  return getDateString(date); // Current Date
};

export const getLastTwoMonthsDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 60);
  return getDateString(date); // Date 60 days ago
};

export const getLastMonthDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return getDateString(date); // Date 30 days ago
};

export const getCurrentQuarterDate = () => {
  const date = new Date();
  const currentMonth = date.getMonth();
  const quarterStartMonth = Math.floor(currentMonth / 3) * 3;
  const quarterStartDate = new Date(date.getFullYear(), quarterStartMonth, 1);
  return getDateString(quarterStartDate); // Start of the current quarter
};

export const getLastQuarterDate = () => {
  const date = new Date();
  const currentQuarter = Math.floor((date.getMonth() + 3) / 3);
  let lastQuarterStartMonth = (currentQuarter - 2) * 3; // -2 to go back one quarter
  let year = date.getFullYear();
  if (lastQuarterStartMonth < 0) {
    lastQuarterStartMonth += 12;
    year -= 1;
  }
  const lastQuarterStartDate = new Date(year, lastQuarterStartMonth, 1);
  return getDateString(lastQuarterStartDate); // Start of the last quarter
};

export const getCurrentDateOnly = () => {
  const date = new Date();
  const [day, month, year] = [
    date.getDate(),
    date.getMonth(),
    date.getFullYear(),
  ];
  // *Will return only date month and year like "10-7-2024" //
  return `${day}-${month + 1}-${year}`;
};
