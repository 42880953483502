import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DOMPurify from "dompurify";
import Typography from "@mui/material/Typography";
import Editor from "../../components/texteditor/Editor";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  createAutomation,
  getAutomationRule,
  getAutomationTriggers,
  getEmailTemplateByEventName,
} from "../../services/attributes.service";
import { toast } from "react-toastify";
import { validateStringLength } from "../../utils/validateStringLength";
import { hasSpecialCharacter } from "../../utils/hasSpecialCharacter";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../../services/users.service";
import {
  fecthModuleAndEvents,
  getActionNames,
} from "../../services/common.service";
import users from "../../_mock/user";
import { ContactSupportOutlined } from "@material-ui/icons";
import ReactQuill from "react-quill";
import { validateString } from "../../utils/validateString";
const steps = ["Select trigger", "Select action", "Review and save"];
export default function HorizontalNonLinearStepper({
  moduleName,
  ruleData,
  setRueleData,
  isAutomationListFetched,
  setIsAutomationListFetched,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [isTrue, setIsTrue] = React.useState(true);
  const [triggerData, setTriggerData] = React.useState({
    moduleName: "",
    triggerValue: "",
    triggerFunctionality: "",
  });
  const [tempTriggerData, setTempTriggerData] = React.useState({
    moduleName: "",
    triggerValue: "",
    triggerFunctionality: "",
  });

  const [actionData, setActionData] = React.useState({
    description: "",
    role: ["Sales Owner", "Tech Owner"],
    selectedRole: [],
    users: [],
    watchers: false,
    status: "",
    selectedDay: "",
  });
  const [emailData, setEmailData] = React.useState({
    subject: "",
    body: "",
    role: ["Sales Owner", "Tech Owner"],
    selectedRole: [],
    users: [],
    watchers: false,
    status: "",
    object: "",
    nestedObject: "",
  });
  const [tempActionData, setTempActionData] = React.useState({
    description: "",
    role: ["Sales Owner", "Tech Owner"],
    selectedRole: [],
    users: [],
    status: "",
    selectedDay: "",
  });
  const [tempEmailData, settempEmailData] = React.useState({
    subject: "",
    body: "",
    role: ["Sales Owner", "Tech Owner"],
    selectedRole: [],
    users: [],
    updateStatus: "",
    object: "",
    nestedObject: "",
  });
  const [emailTempData, setEmailTempData] = React.useState(null);

  const [trigger, setTrigger] = React.useState("");
  const [action, setAction] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState({
    open: false,
    actionString: "",
  });
  const options = ["Sales Owner", "Tech Owner"];
  const [selected, setSelected] = React.useState([]);
  const [userData, setUserData] = React.useState([]);
  const [isActionOpen, setIsActionOpen] = React.useState(false);
  const [selectTrigger, setSelectTrigger] = React.useState([]);
  const [moduleEvents, setModuleEvents] = React.useState([]);
  const [watcherCheck, setWatcherCheck] = React.useState(false);

  //* Customize Actions

  const [actionNames, setActionNames] = React.useState([]);
  async function getAllActionNames() {
    try {
      const response = await getActionNames();
      if (response.data.status) {
        setActionNames([...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const isAllUserSelected =
    userData.length > 0 && actionData?.users.length === userData.length;
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  let toastId = null;
  const navigate = useNavigate();
  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "justify",
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "start",
  }));

  const fecthEvents = () => {
    fecthModuleAndEvents()
      .then((res) => {
        if (res.status) {
          setModuleEvents((prev) => (prev = res.data.data));
        }
      })
      .catch((err) => toast(err));
  };
  function convertToTitleCase(str) {
    if (str === "toDO") {
      return str;
    }
    return str.replace(/([A-Z])/g, " $1").trim();
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };
  const handleEmailNext = () => {
    let actionString;

    if (triggerData.triggerFunctionality === "Email") {
      if (!emailTempData) {
        toast.warn(
          `There is no email template for this specific event ${triggerData.triggerValue} please create the email template`
        );
        return;
      }
      let user = actionData.users.length
        ? actionData.users.length === 1
          ? actionData.users.join(", ")
          : `${actionData.users
              .slice(0, -1)
              .join(", ")} and ${actionData.users.slice(-1)}`
        : "";
      // let emailWatcher = emailData.watchers ? " Watchers, " : "";
      actionString = `an ${
        triggerData.triggerFunctionality
      } with the subject "${emailTempData.subject}" should be send to ${
        emailTempData?.to ? emailTempData.to : ""
      }, ${emailTempData?.CC?.length > 0 ? emailTempData?.CC?.join(",") : ""}.`;

      setAction(actionString);
      setOpen(true);
      setIsActionOpen(true);

      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const handelAddTriggerButton = () => {
    setIsTrue(false);
  };

  const handleTriggerChange = (event) => {
    const { name, value } = event.target;
    setTriggerData({
      ...triggerData,
      [name]: value,
    });
  };
  console.log(triggerData, "Triggered data");

  const getEmailTemplateByEvent = async (moduleName, eventName) => {
    try {
      const result = await getEmailTemplateByEventName(
        moduleName.toLocaleUpperCase(),
        eventName
      );
      if (result) {
        setEmailTempData(result.data.data);
      } else {
        setEmailTempData(null);
      }

      console.log(result.data, "modata");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddTrigger = async () => {
    let triggerString;
    if (triggerData.triggerValue === "") {
      toast("Select Trigger can not be empty");
      return;
    }
    if (triggerData.triggerFunctionality === "") {
      toast("Trigger Functionality can not be empty");
      return;
    }
    if (
      triggerData.triggerValue === "Add Action" &&
      triggerData.triggerFunctionality === "Action"
    ) {
      toast("Select trigger and Trigger functionality can not be same");
      return;
    }

    let leadStatus = actionData.status !== "" ? `(${actionData.status})` : "";
    triggerString = `In ${moduleName} module, when ${triggerData.triggerValue} ${leadStatus} functionality is triggered,`;
    if (
      triggerData.triggerValue !== "" &&
      triggerData.triggerFunctionality !== ""
    ) {
      if (triggerData.triggerFunctionality === "Do Nothing") {
        triggerString = `In ${moduleName} module, when ${triggerData.triggerValue} functionality is triggered, then ${triggerData.triggerFunctionality}`;
      }

      try {
        if (triggerData.triggerFunctionality === "Email") {
          await getEmailTemplateByEvent(moduleName, triggerData.triggerValue);
        }

        setTrigger(triggerString);
        setOpen(true);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEditTrigger = () => {
    setOpen(false);
  };
  const handleDeleteTrigger = () => {
    if (trigger !== "") {
      setTrigger("");
      setOpen(false);
      setTriggerData(tempTriggerData);
    }
  };

  const handleActionChange = (event) => {
    const { name, value } = event.target;
    if (value.length > 25) {
      if (toastId == null) {
        toastId = toast.warning(
          "Action name should not be more then 25 characters."
        );
      } else {
        toast.update(
          toastId,
          "Action name should not be more then 25 characters."
        );
      }
    } else {
      setActionData({
        ...actionData,
        [name]: value,
      });
    }
  };

  const handleSubjectChange = (event) => {
    if (event.target.value && !event.target.value?.trim()) {
      toast.warning("Subject can not contain only space");
      return;
    }
    const { name, value } = event.target;
    if (value.length > 100) {
      if (toastId == null) {
        toastId = toast.warning(
          "Subject should not be more then 100 characters."
        );
      } else {
        toast.update(
          toastId,
          "Subject should not be more then 100 characters."
        );
      }
    } else {
      setEmailData({
        ...emailData,
        [name]: value,
      });
    }
  };
  const handleEmailBodyChange = (event) => {
    const htmlString = (txt) => {
      // Your HTML string
      const htmlString = txt;

      // Create a new DOMParser
      const parser = new DOMParser();

      // Parse the HTML string into a DOM document
      const dom = parser.parseFromString(htmlString, "text/html");

      // Access the parsed content
      const plainText = dom.body.textContent;

      return plainText;
    };

    if (!htmlString(event)?.trim()) {
      toast.warn("Body can not contain only space");
      return;
    }
    setEmailData((prev) => ({
      ...prev,
      body: event,
    }));
  };

  const handleChange = (event) => {
    setEmailData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name === "module") {
      setEmailData((prev) => ({
        ...prev,
        subject: "",
        body: "",
        role: ["Sales Owner", "Tech Owner"],
        selectedRole: [],
        users: [],
        status: "",
        object: "",
        nestedObject: "",
      }));
    }
    if (
      event.target.name === "object" &&
      "clipboard" in navigator &&
      event.target.value !== "Add Action" &&
      event.target.value !== "Add Comment" &&
      event.target.value !== "Mark As Done"
    ) {
      navigator.clipboard.writeText(
        "${" + `${moduleName.toLowerCase()}.` + event.target.value + "}"
      );
      toast("Text copied to clipboard");
    }
    const removeSpace = triggerData.triggerValue.indexOf(" ");
    const triggerNamed =
      triggerData.triggerValue === "Mark As Done"
        ? "Add  Action"
        : triggerData.triggerValue;

    if (event.target.name === "nestedObject" && "clipboard" in navigator) {
      navigator.clipboard.writeText(
        "${" +
          `${moduleName.toLowerCase()}.` +
          `${triggerNamed.substring(removeSpace + 1).toLowerCase()}.` +
          event.target.value +
          "}"
      );
      toast("Text copied to clipboard");
    }
  };

  const objectState = {
    Contacts: [
      "salesOwner",
      "name",
      "company",
      "email",
      "country",
      "phone",
      "linkedIn",
    ],
    Leads: [
      "salesOwner",
      "techOwner",
      "summary",
      "status",
      "watcher",
      "description",
    ],
    Projects: [
      "salesOwner",
      "techOwner",
      "title",
      "details",
      "specification",
      "status",
    ],
  };
  const nestedObjectState = {
    addaction: ["toDo", "assignedBy", "doer", "dueDate", "doneOn"],
    addcomment: ["comment", "createdBy"],
    markasdone: ["toDo", "assignedBy", "doer", "dueDate", "doneOn"],
  };

  // const handleEvents = () => {
  //   let keys = [];
  //   const result = moduleEvents.find(
  //     (ele) => ele.moduleName === triggerData.moduleName
  //   );
  //   if (result) {
  //     result.keys.forEach((ele) => {
  //       if (typeof ele === "object") {
  //         Object.keys(ele).forEach((childEle) => {
  //           keys.push(childEle);
  //         });
  //       } else {
  //         keys.push(ele);
  //       }
  //     });
  //     return { events: result.events, objects: keys };
  //   }
  //   return [];
  // };

  const handleAddAction = () => {
    // console.log(
    //   "triggerData:::",
    //   triggerData.triggerValue,
    //   triggerData.triggerFunctionality
    // );

    function removeExtraWhiteSpace(inputValue) {
      const trimmedValue = inputValue?.trim().replace(/\s+/g, " ");

      if (trimmedValue === "") {
        return;
      }

      return trimmedValue;
    }
    let iswSubjectContainWhiteSpace = removeExtraWhiteSpace(emailData?.subject);
    let isBodyContainWiteSpace = removeExtraWhiteSpace(emailData?.body);
    // const htmlString = (txt) => {
    //   // Your HTML string
    //   const htmlString = txt;

    //   // Create a new DOMParser
    //   const parser = new DOMParser();

    //   // Parse the HTML string into a DOM document
    //   const dom = parser.parseFromString(htmlString, "text/html");

    //   // Access the parsed content
    //   const plainText = dom.body.textContent;

    //   return plainText;
    // };

    // if (!validateString(iswSubjectContainWhiteSpace)) {
    //   toast("Subject can not be empty");
    //   return;
    // }
    // if (htmlString(isBodyContainWiteSpace).trim() === "") {
    //   toast("Body can not be empty");
    //   return;
    // }
    let value = removeExtraWhiteSpace(actionData?.description);
    // if (actionData?.description === "" || actionData?.description === null) {
    //   toast("Action Name can not be empty");
    //   return;
    // }
    if (triggerData.triggerFunctionality === "Action") {
      if (actionData?.description === "" || actionData?.description === null) {
        toast("Action Name can not be empty");
        return;
      }
    } else if (triggerData.triggerFunctionality === "Email") {
      if (emailData?.subject === "" || emailData?.subject === null) {
        toast("Subject can not be empty");
        return;
      }
      if (emailData?.body === "" || emailData?.body === null) {
        toast("Body can not be empty");
        return;
      }
    }

    setActionData((prev) => {
      let temp = prev;
      temp.description = value;
      return temp;
    });
    if (selected?.length === 0) {
      toast("Select role can not be empty");
      return;
    }
    // if (actionData?.selectedDay === "") {
    //   toast("Select day can not be empty");
    //   return;
    // }
    const hasContainSpecialChar = hasSpecialCharacter(value);
    if (hasContainSpecialChar) {
      toast("Special characters are not allowed");
      return;
    }

    // const checkMinMaxLengthOfString = validateStringLength(value, 2, 25);

    // if (!checkMinMaxLengthOfString) {
    //   toast("Action name should be in between 2 to 25 character");
    //   return;
    // }

    // let actionString = `A new action ${
    //   actionData.description
    // }   assign to ${selected?.join(",")}  due day is ${
    //   actionData.selectedDay
    //   } `;

    // let actionString;
    // // let user = actionData.users.length ? `${actionData.users.join(", ")}` : "";
    // let user = actionData.users.length
    //   ? actionData.users.length === 1
    //     ? actionData.users.join(", ")
    //     : `${actionData.users
    //         .slice(0, -1)
    //         .join(", ")} and ${actionData.users.slice(-1)}`
    //   : "";

    // actionString = `named ${actionData.description} should be assigned to ${
    //   moduleName === "Contacts" ? selected : selected?.join(",")
    // }, ${user} .`;

    let actionString;
    if (triggerData.triggerFunctionality === "Action") {
      if (actionData.selectedDay === 1) {
        let user = actionData.users.length
          ? `${actionData.users.join(", ")}`
          : "";
        let actionWatcher = actionData.watchers ? " Watchers, " : "";
        actionString = `an ${triggerData.triggerFunctionality} named ${
          actionData.description
        } should be assigned to ${
          moduleName === "Contacts" ? selected : selected?.join(",")
        },${actionWatcher} ${user} which has to be completed within ${
          actionData.selectedDay
        } days of trigger.`;
      } else if (actionData.selectedDay === "") {
        let user = actionData.users.length
          ? `${actionData.users.join(", ")}`
          : "";
        let actionWatcher = actionData.watchers ? " Watchers, " : "";
        actionString = `an ${triggerData.triggerFunctionality} named ${
          actionData.description
        } should be assigned to ${
          moduleName === "Contacts" ? selected : selected?.join(",")
        },${actionWatcher} ${user} which has to be completed within same day of trigger.`;
      } else {
        let user = actionData.users.length
          ? `${actionData.users.join(", ")}`
          : "";
        let actionWatcher = actionData.watchers ? " Watchers, " : "";
        actionString = `an ${triggerData.triggerFunctionality} named ${
          actionData.description
        } should be assigned to ${
          moduleName === "Contacts" ? selected : selected?.join(",")
        },${actionWatcher} ${user} which has to be completed within ${
          actionData.selectedDay
        } days of trigger.`;
      }
    } else if (triggerData.triggerFunctionality === "Email") {
      let user = actionData.users.length
        ? actionData.users.length === 1
          ? actionData.users.join(", ")
          : `${actionData.users
              .slice(0, -1)
              .join(", ")} and ${actionData.users.slice(-1)}`
        : "";
      let emailWatcher = emailData.watchers ? " Watchers, " : "";
      actionString = `an ${
        triggerData.triggerFunctionality
      } with the subject "${emailData.subject}" should be send to ${
        moduleName === "Contacts" ? selected : selected?.join(",")
      },${emailWatcher} ${user}.`;
    }

    if (
      actionData.description !== "" &&
      // actionData.selectedRole !== "" &&
      selected !== ""
      //&& actionData.selectedDay !== ""
    ) {
      setAction(actionString);
      setOpen(true);
      setIsActionOpen(true);
    }
  };

  // const handleClose = () => {
  //   setOpenDialog((prev) => ({ ...prev, open: false }));
  // };

  const handleWatcherCheck = (event) => {
    setActionData((prev) => ({ ...prev, watchers: event.target.checked }));
    setEmailData((prev) => ({ ...prev, watchers: event.target.checked }));
    // setWatcherCheck(true);
  };

  const handleEditAction = () => {
    setIsActionOpen(false);
  };
  const handleDeleteAction = () => {
    if (action !== "") {
      setAction("");
      setActionData(tempActionData);
      setEmailData(tempEmailData);
      setSelected([]);
      setIsActionOpen(false);
    }
  };

  const handelMltiSelectDropdown = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === "All") {
      if (selected.length === options.length) {
        // temp[componentIndex]["selectedValues"] = [];
        setSelected([]);
      } else {
        setSelected(options);
      }
      return;
    }

    setSelected(value);
  };

  const handelMltiSelectDropdownForUser = (event) => {
    const value = event.target.value;

    console.log("xcdsfd", value, userData);

    if (value.includes("All")) {
      if (actionData.users.length === userData.length) {
        // If "All" was previously selected, unselect it
        setActionData((prev) => ({
          ...prev,
          users: [],
        }));
      } else {
        // If "All" was not previously selected, select all users
        setActionData((prev) => ({
          ...prev,
          users: userData.map((ele) => ele.name),
        }));
      }
    } else {
      // If individual users are selected, update the selection accordingly
      setActionData((prev) => ({
        ...prev,
        users: value,
      }));
    }
  };

  const handelSelectDropdownForContact = (event) => {
    const value = event.target.value;

    setSelected([value]);
  };

  const handleEmailData = () => {
    let data = null;
    if (selected.length > 1) {
      const findEmails = actionData.users.reduce((storage, val) => {
        const temp = userData.find((ele) => ele.name === val);
        if (temp) {
          storage.push(temp.email);
        }
        return storage;
      }, []);
      console.log("actionData :::", actionData.users, data);
      const selectedData = selected[0];
      const selectedCCData = [...selected?.filter((e) => e !== selected[0])];
      data = {
        to: `\${${
          selectedData === "Sales Owner"
            ? "salesOwner"
            : selectedData === "Tech Owner"
            ? "techOwner"
            : selectedData
        }}`,
        CC: [
          `\${${
            selectedCCData[0] === "Tech Owner" ? "techOwner" : selectedCCData[0]
          }}`,
          ...findEmails,
        ],
        BCC: [],
        moduleName: moduleName.toUpperCase(),
        eventName: triggerData.triggerValue,
        subject: emailData.subject,
        body: emailData.body,
      };
    } else {
      const findEmails = actionData.users.reduce((storage, val) => {
        const temp = userData.find((ele) => ele.name === val);
        if (temp) {
          storage.push(temp.email);
        }
        return storage;
      }, []);
      console.log("actionData :::", actionData.users, data);
      const selectedData = selected[0];
      data = {
        to: `\${${
          selectedData === "Sales Owner"
            ? "salesOwner"
            : selectedData === "Tech Owner"
            ? "techOwner"
            : selectedData
        }}`,
        CC: [...findEmails],
        BCC: [],
        moduleName: moduleName.toUpperCase(),
        eventName: triggerData.triggerValue,
        subject: emailData.subject,
        body: emailData.body,
      };
    }
    return data;
  };

  //Handel save rule
  const handleSaveRule = async () => {
    let body;
    try {
      const userIdArray = actionData.users.map((username) => {
        const userObject = userData.find(
          (userDataItem) => userDataItem.name === username
        );
        return userObject ? userObject._id : null;
      });
      console.log(
        "selecuserIdArrayted",
        userIdArray,
        actionData.users,
        userData,
        selected
      );
      const tempEmail = handleEmailData();
      const dueDaysValue =
        actionData?.selectedDay === "" ? 0 : actionData?.selectedDay;

      if (triggerData.triggerFunctionality === "Do Nothing") {
        body = {
          moduleName: moduleName.toLocaleUpperCase(),
          eventName: triggerData.triggerValue,
          triggerFunctionality: triggerData.triggerFunctionality,
          actionName: actionData.description,
          userRole: selected,
          ruleName: `${trigger} ${action}`,
          isRuleRequired: true,
          dueDays: dueDaysValue,
          users: userIdArray,
          watchers: actionData.watchers || emailData.watchers,
          updateStatus: "",
          // emails: tempEmail,
          emails: null,
        };
      }

      body = {
        moduleName: moduleName.toLocaleUpperCase(),
        eventName: triggerData.triggerValue,
        triggerFunctionality: triggerData.triggerFunctionality,
        actionName: actionData.description,
        userRole: selected,
        ruleName: `${trigger} ${action}`,
        isRuleRequired: true,
        dueDays: dueDaysValue,
        users: userIdArray,
        watchers: actionData.watchers || emailData.watchers,
        updateStatus:
          moduleName === "Projects"
            ? actionData.status
            : actionData.status.toLocaleLowerCase(),
        // emails: tempEmail,
        emails:
          triggerData.triggerFunctionality === "Email"
            ? emailTempData._id
            : null,
      };
      const response = await createAutomation(body);
      if (response.data.status) {
        toast("Rule created successfully");
        navigate(`/customize/${moduleName}`);
      }
    } catch (error) {
      if (error.response.data.message === "Automation Rule already exists!") {
        toast("Automation Rule already exists!");
      }
      console.log(error);
    }
  };

  const fetchGetAutomationTriggers = async () => {
    try {
      const result = await getAutomationTriggers();
      let filterSelectTrigger = result.data?.data?.filter((item) => {
        return item.moduleName === moduleName.toLocaleUpperCase();
      })[0];

      setSelectTrigger(filterSelectTrigger.events);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const result = await getUsers(1, true);
      console.log("Hellosd", result.data.data);
      setUserData(result.data.data);
      // setSelectTrigger(filterSelectTrigger.events);
    } catch (error) {
      console.log("Error", error);
    }
  };

  React.useEffect(() => {
    getAllActionNames();
    fetchGetAutomationTriggers();
    fetchUsers();
    fecthEvents();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            {/* <StepButton color="inherit" onClick={handleStep(index)}> */}
            <StepButton color="inherit">{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid container spacing={{ xs: 1, md: 1 }} sx={{ mb: 2 }}>
              {open && trigger !== "" && activeStep == 0 && (
                <>
                  <Grid
                    item
                    xs={7}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Item>
                      <Typography fontWeight="bold">{`Trigger :`}</Typography>
                      <Typography style={{ marginLeft: "5px" }}>
                        {trigger}
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid
                    item
                    xs={0.5}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <IconButton
                      sx={{ "&:hover": { color: "#2056D1" } }}
                      onClick={handleEditTrigger}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    xs={0.5}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <IconButton
                      sx={{ "&:hover": { color: "#FF4842" } }}
                      onClick={handleDeleteTrigger}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </>
              )}
              {open && trigger !== "" && activeStep == 1 && (
                <>
                  <Grid
                    item
                    xs={10}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Item>
                      <Typography fontWeight="bold">{`Trigger :`}</Typography>
                      <Typography style={{ marginLeft: "5px" }}>
                        {trigger}
                      </Typography>
                    </Item>
                  </Grid>
                </>
              )}
              {open && trigger !== "" && activeStep == 2 && (
                <>
                  <Grid
                    item
                    xs={8}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Item>
                      <Typography fontWeight="bold">{`Trigger :`}</Typography>
                      <Typography style={{ marginLeft: "5px" }}>
                        {trigger}
                      </Typography>
                    </Item>
                  </Grid>
                </>
              )}

              {/* show action */}
              {open && trigger !== "" && activeStep == 1 && isActionOpen && (
                <>
                  <Grid
                    item
                    xs={12}
                    // style={{
                    //   display: "flex",
                    //   justifyContent: "start",
                    //   alignItems: "center",
                    // }}
                  >
                    <Grid container>
                      <Grid item xs={10}>
                        <Grid container>
                          {triggerData.triggerFunctionality === "Email" ? (
                            <>
                              <Grid item xs={12}>
                                <Item>
                                  <Typography>
                                    {
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        Action :
                                      </span>
                                    }{" "}
                                    {action}
                                  </Typography>
                                </Item>
                              </Grid>
                              <Grid item xs={12}>
                                <Item>
                                  <Typography>
                                    {
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        Subject :
                                      </span>
                                    }{" "}
                                    {/* {emailData.subject} */}
                                    {emailTempData.subject
                                      ? emailTempData.subject
                                      : ""}
                                  </Typography>
                                </Item>
                              </Grid>
                              <Grid item xs={12}>
                                <Item>
                                  <Typography
                                    style={{ width: "5.3%" }}
                                    fontWeight="bold"
                                  >
                                    {`Body :`}
                                  </Typography>
                                  <Typography
                                    style={{ marginLeft: "10px" }}
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        // emailData.body ? emailData.body : ""
                                        emailTempData.body
                                          ? emailTempData.body
                                          : ""
                                      ),
                                    }}
                                  />
                                </Item>
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12}>
                              <Item>
                                <Typography>
                                  {
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      Action :
                                    </span>
                                  }{" "}
                                  {action}
                                </Typography>
                              </Item>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      {/* <Grid item xs={2}>
                        <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            item
                            xs={6}
                            // style={{
                            //   // display: "flex",
                            //   justifyContent: "center",
                            //   alignItems: "center",
                            // }}
                          >
                            <IconButton
                              sx={{ "&:hover": { color: "#2056D1" } }}
                              onClick={handleEditAction}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            // style={{
                            //   // display: "flex",
                            //   justifyContent: "center",
                            //   alignItems: "center",
                            // }}
                          >
                            <IconButton
                              sx={{ "&:hover": { color: "#FF4842" } }}
                              onClick={handleDeleteAction}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid> */}
                      <Grid item xs={2}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          style={{ height: "100%" }}
                        >
                          {/* <Grid item xs={6} style={{ textAlign: "center" }}>
                            <IconButton
                              sx={{ "&:hover": { color: "#2056D1" } }}
                              onClick={handleEditAction}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: "center" }}>
                            <IconButton
                              sx={{ "&:hover": { color: "#FF4842" } }}
                              onClick={handleDeleteAction}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid> */}
                          {/* edit comment */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {open && trigger !== "" && activeStep == 2 && isActionOpen && (
                <>
                  <Grid
                    item
                    xs={10}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Item>
                      <Typography>
                        {
                          <span
                            style={{
                              fontWeight: "bold",
                              paddingRight: "5px",
                            }}
                          >
                            Action :
                          </span>
                        }{" "}
                        {action}
                      </Typography>
                    </Item>
                  </Grid>
                </>
              )}
            </Grid>

            {isTrue ? (
              <Grid container sx={{ mt: 5 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handelAddTriggerButton}
                    variant="contained"
                    size="large"
                  >
                    <AddIcon /> {`Add Trigger`}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <>
                {activeStep === 0 && !open && (
                  <Grid container spacing={1} sx={{ mt: 5 }}>
                    <Grid
                      item
                      xs={2}
                      // alignItems="center"
                    >
                      <Item> Module Name: {moduleName}</Item>
                    </Grid>
                    <Grid item xs={2.5} alignItems="center">
                      <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          required
                        >
                          {" "}
                          Select Trigger
                        </InputLabel>
                        <Select
                          required
                          name="triggerValue"
                          value={triggerData.triggerValue}
                          onChange={handleTriggerChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          label={"Select Trigger"}
                        >
                          {selectTrigger.map((selectTriggerOption, index) => {
                            return (
                              <MenuItem key={index} value={selectTriggerOption}>
                                {selectTriggerOption}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {moduleName === "Leads" &&
                      triggerData.triggerValue === "Update Status" && (
                        <Grid item xs={2.5}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-helper-label">
                              Select status
                            </InputLabel>
                            <Select
                              name="status"
                              value={actionData?.status}
                              onChange={handleActionChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              label={"Select status"}
                            >
                              <MenuItem value="Prospect">Prospect</MenuItem>
                              <MenuItem value="Opportunity">
                                Opportunity
                              </MenuItem>
                              <MenuItem value="Drop">Drop</MenuItem>
                              <MenuItem value="Closure">Closure</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    {moduleName === "Projects" &&
                      triggerData.triggerValue === "Update Status" && (
                        <Grid item xs={2.5}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-helper-label">
                              Select status
                            </InputLabel>
                            <Select
                              name="status"
                              value={actionData?.status}
                              onChange={handleActionChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              label={"Select status"}
                            >
                              {/* <MenuItem value="Waiting to start">
                                Waiting to start
                              </MenuItem> */}
                              <MenuItem value="Started">Started</MenuItem>
                              <MenuItem value="Paused">Paused</MenuItem>
                              <MenuItem value="Closed">Closed</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    <Grid item xs={2.5}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">
                          Trigger Functionality
                        </InputLabel>
                        <Select
                          name="triggerFunctionality"
                          value={triggerData.triggerFunctionality}
                          onChange={handleTriggerChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          label={"Trigger Functionality"}
                        >
                          <MenuItem value="Action">Action</MenuItem>
                          <MenuItem value="Email">Email</MenuItem>
                          <MenuItem value="Do Nothing">Do Nothing</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        onClick={handleAddTrigger}
                        variant="contained"
                        size="large"
                        sx={{ width: 10, marginLeft: 2 }}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {/* add action section */}
                {activeStep === 1 && !isActionOpen && (
                  <Grid container spacing={2.5}>
                    {triggerData.triggerFunctionality === "Action" ? (
                      <Grid item xs={2.7}>
                        {/* <TextField
                          label="Action Name"
                          variant="outlined"
                          fullWidth
                          name="description"
                          value={actionData.description}
                          onChange={handleActionChange}
                        /> */}
                        <Autocomplete
                          freeSolo
                          inputValue={actionData.description || ""}
                          value={actionData.description || ""}
                          options={actionNames.map(
                            (action) => action.actionName
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Action Name"
                              placeholder="Action Name"
                              onChange={(e) => {
                                let {
                                  target: { value },
                                } = e;
                                if (value?.length > 30) {
                                  toast.warn(
                                    "Action Name can not be more than 30 characters"
                                  );
                                  return;
                                }
                                setActionData((prev) => ({
                                  ...prev,
                                  description: value,
                                }));
                              }}
                            />
                          )}
                          onChange={(event, value) => {
                            console.log(value);
                            setActionData((prev) => ({
                              ...prev,
                              description: value,
                            }));
                          }}
                          PaperComponent={({ children, ...other }) => (
                            <Paper style={{ width: "520px" }} {...other}>
                              {children}
                            </Paper>
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>{option}</li>
                          )}
                        />
                      </Grid>
                    ) : // <Grid item xs={12}>
                    //   <Grid container spacing={1}>
                    //     <Grid item xs={6}>
                    //       <FormControl fullWidth>
                    //         <InputLabel>Objects</InputLabel>
                    //         <Select
                    //           label="Objects"
                    //           name="object"
                    //           value={emailData.object}
                    //           onChange={handleChange}
                    //           readOnly={!moduleName.length}
                    //         >
                    //           {moduleName.length
                    //             ? objectState[moduleName].map((ele) => (
                    //                 <MenuItem key={ele} value={ele}>
                    //                   {convertToTitleCase(ele).toUpperCase()}
                    //                 </MenuItem>
                    //               ))
                    //             : null}
                    //         </Select>
                    //       </FormControl>
                    //       <span
                    //         style={{
                    //           fontSize: "10px",
                    //           marginLeft: "8px",
                    //           color: "#808080",
                    //         }}
                    //       >
                    //         *To Add An Objects(placeholder), select option
                    //         from above dropdown list it will copy to clipboard
                    //       </span>
                    //     </Grid>
                    //     <Grid item xs={6}>
                    //       <FormControl fullWidth>
                    //         {triggerData.triggerValue === "Add Action" ||
                    //         triggerData.triggerValue === "Add Comment" ||
                    //         triggerData.triggerValue === "Mark As Done" ? (
                    //           <>
                    //             <InputLabel>Nested Objects</InputLabel>
                    //             <Select
                    //               label="Nested Objects"
                    //               name="nestedObject"
                    //               value={emailData.nestedObject}
                    //               onChange={handleChange}
                    //               readOnly={!moduleName.length}
                    //             >
                    //               {triggerData.triggerValue ===
                    //                 "Add Action" ||
                    //               triggerData.triggerValue ===
                    //                 "Add Comment" ||
                    //               triggerData.triggerValue === "Mark As Done"
                    //                 ? nestedObjectState[
                    //                     triggerData.triggerValue
                    //                       .toLowerCase()
                    //                       .replace(/\s/g, "")
                    //                   ].map((ele) => (
                    //                     <MenuItem key={ele} value={ele}>
                    //                       {convertToTitleCase(
                    //                         ele
                    //                       ).toUpperCase()}
                    //                     </MenuItem>
                    //                   ))
                    //                 : null}
                    //             </Select>
                    //           </>
                    //         ) : null}
                    //       </FormControl>
                    //       {triggerData.triggerValue === "Add Action" ||
                    //       triggerData.triggerValue === "Add Comment" ||
                    //       triggerData.triggerValue === "Mark As Done" ? (
                    //         <span
                    //           style={{
                    //             fontSize: "10px",
                    //             marginLeft: "8px",
                    //             color: "#808080",
                    //           }}
                    //         >
                    //           *To Add A Nested Objects(placeholder), select
                    //           option from above dropdown list it will copy to
                    //           clipboard
                    //         </span>
                    //       ) : null}
                    //     </Grid>
                    //   </Grid>
                    // </Grid>
                    null}

                    {/* {triggerData.triggerFunctionality === "Email" && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            required
                            label="Subject"
                            variant="outlined"
                            fullWidth
                            name="subject"
                            value={emailData.subject}
                            onChange={handleSubjectChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ReactQuill
                            style={{
                              width: "100%",
                              fontSize: "24px",
                              border: "1px solid",
                              borderRadius: 10,
                            }}
                            label="Body"
                            variant="outlined"
                            name="body"
                            value={emailData.body || ""}
                            // callback={(det) => {
                            //   setEmailData((prev) => ({
                            //     ...prev,
                            //     body: det,
                            //   }));
                            // }}
                            onChange={handleEmailBodyChange}
                          />
                        </Grid>
                      </>
                    )} */}

                    {triggerData.triggerFunctionality === "Action" &&
                      (moduleName === "Contacts" ? (
                        <Grid item xs={2} alignItems="center">
                          <FormControl fullWidth>
                            <InputLabel required>Select Role</InputLabel>
                            <Select
                              required
                              label="Select Role"
                              name="Role"
                              onChange={handelSelectDropdownForContact}
                              value={selected}
                            >
                              <MenuItem value="Sales Owner">
                                Sales Owner
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid item xs={2} alignItems="center">
                          <FormControl fullWidth>
                            <InputLabel required>Select Role</InputLabel>
                            <Select
                              required
                              label="Select Role"
                              name="Role"
                              multiple
                              onChange={handelMltiSelectDropdown}
                              value={selected}
                              renderValue={(selected) =>
                                selected.length === options.length
                                  ? ["All"]
                                  : selected.join(", ")
                              }
                            >
                              <MenuItem value="All">
                                <Checkbox
                                  checked={isAllSelected}
                                  indeterminate={
                                    selected > 0 && selected < options.length
                                  }
                                />

                                <ListItemText primary="All" />
                              </MenuItem>
                              {options.map((ele, index) => (
                                <MenuItem key={index} value={ele}>
                                  <Checkbox
                                    checked={
                                      isAllSelected
                                        ? true
                                        : selected.indexOf(ele) > -1
                                    }
                                  />

                                  <ListItemText primary={ele} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      ))}

                    {triggerData.triggerFunctionality === "Action" &&
                    moduleName === "Leads" ? (
                      <Grid item xs={1} alignItems="center" display="flex">
                        <FormControlLabel
                          alignItems="center"
                          control={
                            <Checkbox
                              checked={
                                actionData?.watchers || emailData?.watchers
                              }
                              onClick={handleWatcherCheck}
                            />
                          }
                          label="Watchers"
                        />
                      </Grid>
                    ) : null}

                    {triggerData.triggerFunctionality === "Action" && (
                      <Grid item xs={2.9} alignItems="center">
                        <FormControl fullWidth>
                          <InputLabel>Select User</InputLabel>
                          <Select
                            label="Select User"
                            name="Users"
                            multiple
                            onChange={handelMltiSelectDropdownForUser}
                            value={actionData?.users}
                            renderValue={(renderArray) =>
                              renderArray.length === userData?.length
                                ? ["All"]
                                : renderArray.join(", ")
                            }
                          >
                            <MenuItem value="All">
                              <Checkbox
                                checked={isAllUserSelected}
                                indeterminate={
                                  actionData?.users > 0 &&
                                  actionData?.users < userData?.length
                                }
                              />

                              <ListItemText primary="All" />
                            </MenuItem>
                            {userData?.map((ele, index) => {
                              return (
                                <MenuItem key={index} value={ele.name}>
                                  <Checkbox
                                    checked={
                                      isAllUserSelected
                                        ? true
                                        : actionData?.users.indexOf(ele.name) >
                                          -1
                                    }
                                  />

                                  <ListItemText primary={ele.name} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {/* {actionData?.status !== "" && (
                      <Grid item xs={3} alignItems="center">
                        <FormControl fullWidth>
                          <InputLabel>Select user for lead status </InputLabel>
                          <Select
                            label="Select user for lead status"
                            name="leadStausUser"
                            multiple
                            onChange={handelMltiSelectDropdownForUser}
                            value={actionData?.leadStausUser}
                            renderValue={(renderArray) =>
                              renderArray.length === userData?.length
                                ? ["All"]
                                : renderArray.join(", ")
                            }
                          >
                            <MenuItem value="All">
                              <Checkbox
                                checked={isAllUserSelected}
                                indeterminate={
                                  actionData?.users > 0 &&
                                  actionData?.users < userData?.length
                                }
                              />

                              <ListItemText primary="All" />
                            </MenuItem>
                            {userData?.map((ele, index) => {
                              return (
                                <MenuItem key={index} value={ele.name}>
                                  <Checkbox
                                    checked={
                                      isAllUserSelected
                                        ? true
                                        : actionData?.users.indexOf(ele.name) >
                                          -1
                                    }
                                  />

                                  <ListItemText primary={ele.name} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    )} */}

                    {triggerData.triggerFunctionality === "Action" && (
                      <Grid item xs={2}>
                        <FormControl fullWidth>
                          <InputLabel>Select Due Days</InputLabel>
                          <Select
                            label="Select Due Days"
                            name="selectedDay"
                            value={actionData?.selectedDay}
                            onChange={handleActionChange}
                          >
                            {days.map((day) => (
                              <MenuItem key={day} value={day}>
                                {day}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        onClick={handleAddAction}
                        variant="contained"
                        size="large"
                        sx={{ width: 10, marginLeft: 2 }}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {activeStep > 0 && (
                <Button
                  //   color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, mt: 0 }}
                  variant="contained"
                  size="large"
                >
                  Back
                </Button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />
              {open && !isTrue && activeStep === 0 && trigger !== "" && (
                <>
                  {triggerData.triggerFunctionality === "Do Nothing" ? (
                    <Button
                      sx={{ mr: 1, mt: 0 }}
                      onClick={handleSaveRule}
                      variant="contained"
                      size="large"
                    >
                      Save
                    </Button>
                  ) : triggerData.triggerFunctionality === "Email" ? (
                    <Button
                      onClick={handleEmailNext}
                      sx={{ mr: 1, mt: 0 }}
                      variant="contained"
                      size="large"
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      onClick={handleNext}
                      sx={{ mr: 1, mt: 0 }}
                      variant="contained"
                      size="large"
                    >
                      Next
                    </Button>
                  )}
                </>
              )}
              {isActionOpen && !isTrue && activeStep === 1 && action !== "" && (
                <Button
                  onClick={handleNext}
                  sx={{ mr: 1, mt: 0 }}
                  variant="contained"
                  size="large"
                >
                  Next
                </Button>
              )}
              {!isTrue && activeStep === 2 && (
                <Button
                  sx={{ mr: 1, mt: 0 }}
                  onClick={handleSaveRule}
                  variant="contained"
                  size="large"
                >
                  Save
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
