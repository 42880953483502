import axios from "axios";
import { getServerUrl } from "../config";

/** Pass one of the following values for their intended environment:
 * "STAGING": for staging build
 * "PRODUCTION": for production build
 * any value other than these would return localhost
 */
export const environment = {
  PRODUCTION: "PRODUCTION",
  STAGING: "STAGING",
  CLIENT: "CLIENT",
  LOCAL: "",
};
const API_URL = getServerUrl(environment.STAGING);

export async function hitPostApi(path, body) {
  return await hitAxiosPostApi(`${API_URL}${path}`, body);
}

export async function hitPostApiWithFormData(path, body) {
  return await hitAxiosPostApiWithFormData(`${API_URL}${path}`, body);
}

export async function hitGetApi(path) {
  return await hitAxiosGetApi(`${API_URL}${path}`);
}

export async function hitPutApi(path, body) {
  return await hitAxiosPutApi(`${API_URL}${path}`, body);
}

// export async function hitDeleteApi(path) {
//   return await hitAxiosDeleteApi(`${API_URL}${path}`);
// }

export async function hitDeleteApi(path, body) {
  // console.log("body", body);
  return await hitAxiosDeleteApi(`${API_URL}${path}`, body);
}

export async function hitChangePasswordApi(path, password, token) {
  return await axios.put(
    `${API_URL}${path}/changePassword`,
    { password: password },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
}

export async function hitDirectChangePasswordApi(path, body) {
  return await hitAxiosPutApi(`${API_URL}${path}/directChangePassword`, body);
}

export async function hitPagedApi(path, page, sortBySwitch) {
  return await hitAxiosGetApi(
    `${API_URL}${path}?page=${page}&sortBySwitch=${sortBySwitch}`
  );
}

export async function hitPagedApiForUsers(
  path,
  page,
  data,
  fromAction = false
) {
  return await hitAxiosGetApi(
    `${API_URL}${path}?page=${page}&forDetails=${data}&fromAction=${fromAction}`
  );
}

export async function hitAuthLessPostApi(path, body) {
  return await hitAxiosPostApi(`${API_URL}/auth/v1${path}`, body);
}

async function hitAxiosPostApi(url, body) {
  return await axios.post(url, body, {
    headers: {
      accept: "*/*",
      contentType: "application/json",
      authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
    },
  });
}

async function hitAxiosPostApiWithFormData(url, body) {
  return await axios.post(url, body, {
    headers: {
      accept: "*/*",
      contentType: "multipart/form-data",
      authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
    },
  });
}

async function hitAxiosGetApi(url) {
  return await axios.get(url, {
    headers: {
      accept: "*/*",
      contentType: "application/json",
      authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
    },
  });
}

async function hitAxiosPutApi(url, body) {
  return await axios.put(url, body, {
    headers: {
      accept: "*/*",
      contentType: "application/json",
      authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
    },
  });
}

async function hitAxiosDeleteApi(url) {
  return await axios.delete(url, {
    headers: {
      accept: "*/*",
      contentType: "application/json",
      authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
    },
  });
}

// module.exports = {
//   hitAuthLessPostApi,
//   hitPagedApi,
//   hitPostApi,
//   hitGetApi,
//   hitPutApi,
//   hitDeleteApi,
// };
