import * as React from "react";
import { filter, set } from "lodash";
import { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  getContacts,
  getfilteredContacts,
  getLinkedInUseDetails,
  scanDoc,
  scanVisistingcard,
  updateContact,
  uploadExcelFileApi,
} from "../../services/contacts.service";
import CircularProgress from "@mui/material/CircularProgress";

import LinearProgress from "@mui/material/LinearProgress";

import InfoIcon from "@mui/icons-material/Info";
import SyncIcon from "@mui/icons-material/Sync";

import Tesseract from "tesseract.js";
// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableHead,
  Paper,
  Snackbar,
} from "@mui/material";

//--callEmail
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import EmailDialog from "../../components/Dialogs/EmailDialog";

// components
import Page from "../../components/UI/Page";
import Scrollbar from "../../components/UI/Scrollbar";

import SearchNotFound from "../../components/SearchNotFound";
import { UserListHead } from "../../sections/@dashboard/user";

import SearchContact from "../../components/SearchDropDown/SearchDropDownContact";
import { AuthContext } from "../../context/auth.context";
import { toast } from "react-toastify";

import { CallingContext } from "../../context/calling.context";
import { getPassword } from "../../utils/indexedDb";

//Floating Components

import { useMatchCollectionName } from "../../utils/filterObject";
import { useDynamicCompanyTitle } from "../Companies/Companies";
import { useDynamicLeadTitle } from "../Leads/Leads";
import getActivityFeed from "../../services/callHippo.service";
import { createStyles, makeStyles } from "@material-ui/core";
import FileUpload from "../../components/Fileupload/FileUpload/FileUpload";

import FileList from "../../components/Fileupload/FileList/FileList";
import LoaderDialouge from "../../components/Dialogs/LoaderDialouge";

import ContactSplitButton from "./ContactSplitButton";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  getCurrentDate,
  getCurrentMonthDate,
  getCurrentQuarterDate,
  getDateString,
  getLastMonthDate,
  getLastQuarterDate,
  getLastTwoMonthsDate,
} from "../../utils/dateUtils";
import { validateLinkedinUrl } from "../../utils/regex.utils";

// ----------------------------------------------------------------------

export const useDynamicContactTitle = () => {
  let matchedCollection = useMatchCollectionName("contacts");
  return matchedCollection;
};

//-----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Contact({ setToEdit }) {
  const companyDynamicTitle = useDynamicCompanyTitle();
  const contactDynamicTitel = useDynamicContactTitle();
  const leadDynamicTitle = useDynamicLeadTitle();
  const TABLE_HEAD = [
    { id: "name", label: "Name", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    {
      id: "company",
      label: companyDynamicTitle?.displayName.singularName,
      alignRight: false,
    },
    { id: "country", label: "Country", alignRight: false },
    { id: "phone", label: "Phone", alignRight: false },
    // { id: "lastInteraction", label: "Last Interaction", alignRight: false },
    { id: "action", label: "Action", alignRight: true },
  ];
  const TAG = "Contact.js";
  const {
    currentUser,
    toggleShowMailConsentPopup,
    emailPassword,
    setEmailPassword,
  } = useContext(AuthContext);

  const [contacts, setContacts] = useState([]);
  const [files, setFiles] = React.useState([]);
  const [column, setColumn] = useState("");
  const [typeText, setTypeText] = useState("");
  const [count, setCount] = useState(1);
  const [showChip, setShowChip] = useState(false);
  //This variable is used for UI pagination
  const [page, setPage] = useState(0);

  const [pageCount, setPageCount] = useState(1);

  const [order, setOrder] = useState("");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [customDate, setCustomDate] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadDialog, setFileUploadDialog] = useState(false);
  const [outputText, setOutputText] = useState("");
  //for Email call
  const [mailTo, setMailTo] = useState("");
  const [open, setOpen] = useState(false);
  const [agreeSetPasswordDialog, setAgreeSetPasswordDialog] = useState(false);
  const [contactPhonNumber, setContactPhonNumber] = useState("");
  const [contextMailName, setContextMailName] = useState("");
  const [loaderDialog, setLoaderDialog] = React.useState(false);
  const [contactMailButtonClicked, setContactMailButtonClicked] =
    useState(false);
  const initialIntervals = [
    {
      label: "Last Quarter",
      value: getLastQuarterDate(),
    },
    {
      label: "Current Quarter",
      value: getCurrentQuarterDate(),
    },
    {
      label: "Current Month",
      value: getCurrentDate(), // Current Date
    },
    {
      label: "Last 2 Months",
      value: getLastTwoMonthsDate(), // Date 60 days ago
    },
    {
      label: "Last Month",
      value: getLastMonthDate(), // Date 30 days ago
    },
    {
      label: "Custom",
      value: "Custom",
    },
  ];
  const [intervals, setIntervals] = useState(initialIntervals[3]);

  const [interval, setInterval] = useState(
    new Date(new Date().setDate(new Date().getDate() - 60)).toDateString()
  );
  const [popup, setPopup] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  //for Phone call
  const {
    openCall,
    setOpenCall,
    callTo,
    setCallTo,
    setCallState,
    callState,
    setOpenCallPickedUpDialog,
    callId,
    setCallId,
    timeOut,
    checkCallStatDisabled,
    setCallDisability,
    callDisability,
  } = useContext(CallingContext);
  const navigate = useNavigate();
  const [openCallDialouge, setOpenCallDialouge] = React.useState({
    state: false,
    contactId: "",
  });
  const [isAContactUpdated, setIsAContactUpdated] = useState(true);

  //For LinkedIn Profile Dialog State
  const [linkedInState, setLinkedInState] = useState({
    dialogOpen: false,
    url: "",
    toOpen: function () {
      setLinkedInState({ ...linkedInState, dialogOpen: true });
    },
    onClose: function () {
      setLinkedInState({ ...linkedInState, dialogOpen: false, url: "" });
    },
    handleURLField: function (event) {
      setLinkedInState((prev) => ({ ...prev, url: event.target.value }));
    },
  });

  //Snack bar handling
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "Please wait...",
    toOpen: () => {
      setSnackBar((prev) => ({ ...prev, open: true }));
    },
    onClose: () => {
      setSnackBar((prev) => ({ ...prev, open: false }));
    },
  });

  //**For Excel File *
  const [excelFileState, setExcelFileState] = useState({
    isExcelButtonClicked: false,
    onExcelButtonClick: () => {
      setExcelFileState((prev) => ({
        ...prev,
        isExcelButtonClicked: true,
      }));
    },
  });

  async function uploadExcelFile() {
    try {
      if (!files.length) {
        toast.error("Please select an Excel file");
        return;
      }
      setLoaderDialog(true);
      setExcelFileState((prev) => ({
        ...prev,
        isExcelButtonClicked: false,
      }));
      const formData = new FormData();
      formData.append("xlFile", files[0]);
      const response = await uploadExcelFileApi(formData);
      if (response) {
        toast("Excel file uploaded successfully");
        setFiles([]);
        fetchContact();
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to upload Excel file");
      setFiles([]);
    } finally {
      setLoaderDialog(false);
    }
  }

  async function getDetails() {
    try {
      const isValidLinkedInURL = validateLinkedinUrl(linkedInState.url);
      if (!isValidLinkedInURL) {
        toast("Please provide a valid LinkedIn URL");
        return;
      }
      snackBar.toOpen();
      const { data: { status = false, data = {} } = {} } =
        await getLinkedInUseDetails(linkedInState.url);
      if (status) {
        navigate(`/contacts/createContact`, { state: data });
        return;
      }
      throw new Error("Failed");
    } catch (error) {
      console.log(error);
      // Show error message
      toast.error("Failed to get details");
    } finally {
      snackBar.onClose();
    }
  }

  const handleCloseCallDialouge = () => {
    const temp = { ...openCallDialouge };
    temp.state = false;
    temp.contactId = "";
    setOpenCallDialouge(temp);
    setContactPhonNumber("");
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    if (order === "") setOrder("asc");
    if (order === "asc") setOrder("desc");
    setOrderBy(property);
    if (order === "desc") {
      setOrder("");
      setOrderBy("");
    }
  };
  console.log("outsideContacts ", contacts);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contacts.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id) => {
    console.log("Inside handleClick-----", id);
    navigate(`/contacts/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    console.log("Inside handle change page", newPage);
    if (
      rowsPerPage * (parseInt(newPage) + 1) > contacts.length &&
      contacts.length < count
    ) {
      setPageCount((prevState) => prevState + 1);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    console.log(event.target.value);
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const filteredContacts = applySortFilter(
    contacts,
    getComparator(order, orderBy),
    filterName
  );

  console.log("inside fetch contact", column, typeText);
  function fetchContact() {
    console.log("inside fetch contact", column, typeText);
    if (!typeText) {
      getContacts(pageCount)
        .then((response) => {
          if (response.data.status) {
            //  setContacts((prevState) => [
            //     ...prevState,
            //     ...response.data.data.allContacts,
            //   ]);
            setContacts(response.data.data.allContacts);
            setCount(response.data.data.count);
            setIsLoading(false);
          } else {
            navigate(`/unauthorized`);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code == 401) {
            navigate(`/unauthorized`);
          }
        });
    }
  }

  const isUserNotFound = filteredContacts.length === 0;

  //for mail-call action
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSentMail = (email) => {
    if (emailPassword === "") {
      handleSetPasswordAgree();
    } else {
      setMailTo(email);
      handleClickOpen();
    }
  };

  //check email password set or not

  const handleSetPasswordAgree = () => {
    setAgreeSetPasswordDialog(true);
  };

  const handleClose = () => {
    console.log("handle close", agreeSetPasswordDialog);
    setAgreeSetPasswordDialog(false);
  };

  const handlePasswordAgree = () => {
    handleClose();
    toggleShowMailConsentPopup();
  };

  //for phone action
  const handleClickOpenPhone = (_id, name) => {
    console.log("Button Clicked");
    console.log("name===", name);
    console.log("id===", _id);
    setCallState((prev) => ({
      ...prev,
      isRinging: true,
      isPickedUp: false,
      isConnected: false,
    }));
    setOpenCall(true);
  };

  const validatePhone = (data) => {
    console.log(data.length);
    let upperFlag = "Up";
    let lowerFlag = "Low";
    if (data.length > 0) {
      if (data.length >= 10 && data.length <= 14) {
        const regexPattern = /^\+?\d+$/;
        // return result = regexPattern.test(data);
        if (!regexPattern.test(data)) {
          return upperFlag;
        }
      } else {
        return lowerFlag;
      }
      // return data.regex(/^\+?\d+$/) ? true : false
    }
    return null;
  };
  const updatePhoneNumber = async (contactId) => {
    if (!contactPhonNumber.length) {
      toast("Please Enter Your Phone Number");
      return;
    }
    const flag = validatePhone(contactPhonNumber);
    if (flag === "Low") {
      toast("Invalid phone number length");
      setContactPhonNumber("");
      return;
    }
    const res = await updateContact(contactId, { phone: contactPhonNumber });
    if (res.data.status) {
      toast(
        `${contactDynamicTitel?.displayName.singularName} successfully updated`
      );
      const temp = { ...openCallDialouge };
      temp.state = false;
      temp.contactId = "";
      setOpenCallDialouge(temp);
      setContactPhonNumber((prev) => (prev = ""));
      setContacts([]);
      setIsAContactUpdated(false);
    }
  };
  let toastId = null;
  var regexPhone = /^\+?\d*$/;
  const handleChangePhone = (e) => {
    if (e.target.name === "phone") {
      const value = regexPhone.test(e.target.value);
      if (!value) {
        toast(
          "Alphabets, spaces and special characters except '+' are not allowed"
        );
        return;
      }
    }
    if (e.target.value.length > 14) {
      if (toastId == null) {
        toastId = toast.warning(
          "It should not cross more than 14digits Including Contry code"
        );
        return;
      } else {
        toast.update(
          toastId,
          "It should not cross more than 14digits Including Contry code"
        );
        return;
      }
    }
    setContactPhonNumber(e.target.value);
  };

  useEffect(() => {
    console.log("inside useffect getcontact search");
    fetchContact();
  }, [pageCount, typeText]);

  useEffect(() => {
    if (!isAContactUpdated) {
      fetchContact();
      setIsAContactUpdated(true);
    }
  }, [isAContactUpdated]);

  useEffect(() => {
    console.log("Email Password", emailPassword);
  }, [emailPassword]);

  const indexDbpassword = async () => {
    const getpass = await getPassword();
    if (getpass !== undefined && getpass !== null && getpass !== "") {
      setEmailPassword(getpass);
      console.log("UseEffect triggered");
    }
  };

  useEffect(() => {
    if (emailPassword !== "") {
      if (contactMailButtonClicked) {
        handleSentMail(contextMailName);
      }
    }
    getCallActivityFeed();
  }, []);

  const handleCall = (phoneNumber, contactId, contactName) => {
    console.log(`Initiating call to ${contactName} at ${phoneNumber}`);
    window.chCall(phoneNumber, { contactId, contactName });
  };
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSyncing, setIsSyncing] = useState(false);
  const [isTokenIncorrect, setIsTokenIncorrect] = useState(false);

  const [callDetailsAndManage, setCallDetailsAndManage] = useState({
    loaderEnable: true,
    data: [],
    callLogs: [],
    openLogsDialog: false,
    handleOpenLogsDialog: () => {
      setCallDetailsAndManage((prev) => ({ ...prev, openLogsDialog: true }));
      getCallActivityFeed();
    },
    handleCloseLogsDialog: () => {
      setCallDetailsAndManage((prev) => ({
        ...prev,
        openLogsDialog: false,
        callLogs: [],
      }));
      setHandleCallLogPagination((prev) => ({
        ...prev,
        rowsPerPage: 5,
        page: 0,
      }));
    },
    storeLogs: (logs) => {
      setCallDetailsAndManage((prev) => ({
        ...prev,
        callLogs: [...logs],
      }));
    },
  });

  const [handleCallLogPagination, setHandleCallLogPagination] = useState({
    rowsPerPage: 5,
    page: 0,
    handleChangePage: (event, newPage) => {
      setHandleCallLogPagination((prev) => ({
        ...prev,
        page: +newPage,
      }));
    },
  });

  function showLogs(to) {
    callDetailsAndManage.handleOpenLogsDialog();
    const callDetails = callDetailsAndManage.data.filter(
      (call) => call.to === to
    );

    console.log("callLogs", to);
    callDetailsAndManage.storeLogs([...callDetails]);
  }
  console.log("callLogs", callDetailsAndManage.callLogs);
  const useStyles = makeStyles(() =>
    createStyles({
      rotateIcon: {
        animation: "$spin 2s linear infinite",
      },
      "@keyframes spin": {
        "0%": {
          transform: "rotate(360deg)",
        },
        "100%": {
          transform: "rotate(0deg)",
        },
      },
    })
  );

  let styleForSyncIcon = useStyles();

  const getCallActivityFeed = async () => {
    try {
      setIsSyncing(true);
      const response = await getActivityFeed();
      if (response.isSuccess) {
        setCallDetailsAndManage((prev) => ({
          ...prev,
          data: [...response.data.data.callLogs],
          loaderEnable: false,
        }));
        setIsSyncing(false);
        return response;
      } else {
        setIsSyncing(false);
        setIsTokenIncorrect(true);
      }
    } catch (error) {
      setCallDetailsAndManage((prev) => ({
        ...prev,
        loaderEnable: false,
      }));
    }
  };
  const removeFile = (index) => {
    setFiles((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
  };
  const uploadVisitingCard = async () => {
    console.log("fdgdfggdf5455", currentUser._id, files);
    try {
      if (files.length === 0) {
        toast.warning("Please select visiting card!");
        return;
      }
      setFileUploadDialog((prev) => !prev);
      setLoaderDialog((prev) => !prev);
      const responce = await scanDoc(currentUser._id, files);
      if (responce.data.status) {
        setLoaderDialog((prev) => !prev);
        setFiles([]);
        toast.success("Visiting card upload sucessfully!");
      }
      // else {
      //   toast.error("Visiting card not upload");
      // }
    } catch (error) {
      console.log(error);
      setLoaderDialog((prev) => !prev);
      setFiles([]);
      toast.error("Visiting card not upload catch");
    }
  };

  // Tesseract Implementation
  const handleFileInputChange = (event) => {
    if (files.length === 0) {
      toast.warning("Please select a visiting card!");
      return;
    }
    setLoaderDialog((prev) => !prev);
    // const file = event.target.files[0];
    console.log(files, "inputFiles121");
    processImage(files[0]);
  };
  const scanVisistingcards = async (rawaData) => {
    try {
      const response = await scanVisistingcard(rawaData);
      if (response.data.status) {
        setFiles([]);
        // console.log("chatgpt analysis data", response.data.data);
        return response.data.data;
      }
    } catch (error) {
      console.log(error);
      setIsLoading(() => false);
      setFileUploadDialog((prev) => !prev);
      setLoaderDialog((prev) => !prev);
      setFiles([]);
      toast.error(error.response.data.message);
      return error;
    }
  };
  async function fixKeys(obj) {
    console.log("resultsss", obj);

    return new Promise((resolve, reject) => {
      try {
        const newObj = {};
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            let newKey = key;
            console.log(newKey, "B");

            newKey = newKey.replace(/[-*\s]/g, "");
            // Convert space-separated keys to camelCase
            console.log(newKey, "A");
            if (/\s/.test(newKey)) {
              newKey = newKey.replace(/\s+(\w)/g, (match, firstLetter) =>
                firstLetter.toUpperCase()
              );
            }
            // Convert first letter to lowercase
            newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
            newObj[newKey] = obj[key].replace(/[-*]/g, "").trim(); // Remove leading asterisks and spaces from values
          }
        }
        console.log("resultsss0", newObj);
        resolve(newObj);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }
  const processImage = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          setIsLoading(() => true);
          Tesseract.recognize(
            image,
            "eng", // language code, e.g., 'eng' for English
            { logger: (info) => console.log(info) }
          ).then(({ data: { text } }) => {
            setOutputText(() => text);

            scanVisistingcards(text)
              .then((result) => {
                fixKeys(result).then((res) => {
                  setIsLoading(() => false);
                  setFiles([]);
                  setFileUploadDialog((prev) => !prev);
                  setLoaderDialog((prev) => !prev);
                  navigate("/contacts/createContact", { state: res });
                });
              })
              .catch((err) => {
                console.log(err, "visting card err");
              });
          });
        };
      };
      reader.readAsDataURL(file);
    }
  };
  console.log("setOutputText", outputText);
  const isValidDate = (date) => {
    console.log(date, "reset date ");
    let dates = new Date(date);

    // Check if date is a valid Date object and its year is greater than 1000
    return dates instanceof Date && !isNaN(dates) && dates.getFullYear() > 1000;
  };
  const handleStartDateChange = (e) => {
    console.log("e", e);

    const selectedDate = new Date(e);
    if (!isValidDate(selectedDate)) {
      toast.warn("Invalid date format");
      return;
    }

    if (endDate.getTime() > e) {
      setStartDate(e);
    } else {
      toast("End date can not be less than or equal to start date");
    }
  };

  const handleEndDateChange = (e) => {
    console.log("e", e);
    const selectedDate = new Date(e);
    if (!isValidDate(selectedDate)) {
      toast.warn("Invalid date format");
      return;
    }
    if (startDate.getTime() < e) {
      setEndDate(e);
    } else {
      toast("End date can not be less than or equal to start date");
    }
  };
  const handleSet = () => {
    if (!isValidDate(startDate || endDate)) {
      toast.warn("Invalid  date format");
      return;
    }
    setShowChip(true);
    setPopup(() => false);
    setCustomDate(true);
  };
  const handleIntervalChange = (e, newvalue) => {
    console.log(e.target.name, newvalue, intervals, interval, "value111");
    if (newvalue.value !== "Custom" && interval === "Custom") {
      toast("Close the chip to select another interval");
      return;
    }
    if (newvalue.value === "Custom") {
      console.log(newvalue, "value112");
      setPopup(true);
    }
    setIntervals(() => newvalue);
    setInterval(newvalue.value);
  };
  // console.log(intervals, interval, "value110");
  const formatCustomDate = (value) => {
    const splitValue = value.split(" ");
    splitValue.shift();
    const finalValue = splitValue.join(" ");
    return finalValue;
  };
  const afterRemoveChip = () => {
    setCustomDate(false);
    setShowChip(false);
    setStartDate(new Date().setDate(new Date().getDate() - 1));
    setEndDate(new Date());
    setIntervals(initialIntervals[3]);
    // setPage(0);
    setInterval("");
  };
  const dateFilteredContacts = async () => {
    console.log(
      "result5675",
      column === "Date" && intervals.label === "Custom",
      column === "Date",
      intervals.label === "Custom",
      startDate,
      endDate
    );
    // let from = "Thu Jul 02 2024 15:26:40 GMT+0530 (India Standard Time)";
    // let to = "Wed Jul 03 2024 15:26:40 GMT+0530 (India Standard Time)";
    let from, to;
    if (column === "Date" && intervals.label === "Custom") {
      from = getDateString(startDate);
      to = endDate;
    } else {
      from = intervals.value;
      to = getCurrentMonthDate();
    }

    const result = await getfilteredContacts(from, to);
    setContacts(() => [...result.data.data]);
    setCount(result.data.data.length);
    console.log("result56751", from, to, result.data.data);
  };

  return (
    <>
      {" "}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBar.open}
        onClose={snackBar.onClose}
        message={snackBar.message}
        action={
          <>
            <CircularProgress size={25} color="inherit" />
          </>
        }
        key={"367163"}
      />
      <Dialog open={linkedInState.dialogOpen} fullWidth maxWidth="sm">
        <DialogTitle>LinkedIn URL</DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            variant="outlined"
            label="LinkedIn URL"
            value={linkedInState.url}
            onChange={linkedInState.handleURLField}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              linkedInState.onClose();
            }}
            color="error"
            autoFocus
            disabled={snackBar.open}
          >
            CLOSE
          </Button>
          <Button
            disabled={snackBar.open}
            variant="contained"
            color="primary"
            onClick={getDetails}
          >
            FETCH
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={callDetailsAndManage.openLogsDialog}
        onClose={callDetailsAndManage.handleCloseLogsDialog}
      >
        <DialogTitle>
          Call Logs
          <Button
            onClick={async () => {
              const res = await getCallActivityFeed();
              if (res) {
                showLogs(phoneNumber);
              }
            }}
          >
            <SyncIcon
              className={isSyncing ? styleForSyncIcon.rotateIcon : null}
            />
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          {isTokenIncorrect ? (
            <Typography
              gutterBottom
              textAlign="center"
              fontWeight="700"
              color="#857C7A"
              variant="subtitle1"
              sx={{
                fontSize: "20px",
              }}
            >
              Please verify your Call Hippo Credential!{" "}
            </Typography>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Call Type</TableCell>
                      <TableCell>From</TableCell>
                      <TableCell>To</TableCell>
                      <TableCell>Call Status</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Duration</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {callDetailsAndManage.loaderEnable ? (
                      <TableRow>
                        <TableCell colSpan={7}>
                          {" "}
                          <LinearProgress />
                        </TableCell>
                      </TableRow>
                    ) : !callDetailsAndManage.loaderEnable &&
                      callDetailsAndManage.callLogs.length ? (
                      callDetailsAndManage.callLogs
                        ?.slice(
                          handleCallLogPagination.page *
                            handleCallLogPagination.rowsPerPage,
                          handleCallLogPagination.page *
                            handleCallLogPagination.rowsPerPage +
                            handleCallLogPagination.rowsPerPage
                        )
                        .map((log, index) => {
                          let {
                            callStatus,
                            callType,
                            from,
                            to,
                            date,
                            time,
                            callDuration,
                            caller,
                            callHangupTime,
                          } = log;
                          // const convertedTimeInIST = convertTimeGMT4toIST(time);

                          return (
                            <TableRow key={index}>
                              <TableCell>{date}</TableCell>
                              <TableCell>{callType}</TableCell>
                              <TableCell>{from}</TableCell>
                              <TableCell>{to}</TableCell>
                              <TableCell>{callStatus}</TableCell>
                              <TableCell>{time}</TableCell>
                              <TableCell>{callDuration}</TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell colSpan={3}>
                          <Paper
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              gutterBottom
                              textAlign="center"
                              fontWeight="700"
                              color="#857C7A"
                              variant="subtitle1"
                              sx={{
                                fontSize: "20px",
                              }}
                            >
                              No Call Logs!{" "}
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage=""
                rowsPerPageOptions={[5]}
                component="div"
                count={callDetailsAndManage.callLogs.length}
                rowsPerPage={handleCallLogPagination.rowsPerPage}
                page={handleCallLogPagination.page}
                onPageChange={handleCallLogPagination.handleChangePage}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={callDetailsAndManage.handleCloseLogsDialog}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <Page title="Contact">
        <Container>
          <EmailDialog open={open} setOpen={setOpen} mailTo={mailTo} />
          {/* <CallPickedUpDialog /> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card elevation={5} variant="outlined">
                <CardContent>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <SearchContact
                        setCount={setCount}
                        setContacts={setContacts}
                        contacts={contacts}
                        column={column}
                        setColumn={setColumn}
                        typeText={typeText}
                        setTypeText={setTypeText}
                        setPage={setPage}
                        setPageCount={setPageCount}
                        setIsLoading={setIsLoading}
                        initialIntervals={initialIntervals}
                        intervals={intervals}
                        setIntervals={setIntervals}
                        handleIntervalChange={handleIntervalChange}
                        showChip={showChip}
                        formatCustomDate={formatCustomDate}
                        startDate={startDate}
                        endDate={endDate}
                        afterRemoveChip={afterRemoveChip}
                        dateFilteredContacts={dateFilteredContacts}
                        fetchContact={fetchContact}
                      />
                    </Grid>

                    <Grid
                      item
                      xs="6"
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <ContactSplitButton
                        navigate={navigate}
                        setFileUploadDialog={setFileUploadDialog}
                        handleLinkedInButtonClick={linkedInState.toOpen}
                        handleExcelUploadButtonClick={
                          excelFileState.onExcelButtonClick
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card
                elevation={3}
                variant="outlined"
                sx={{ paddingLeft: 2, paddingRight: 2 }}
              >
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={count}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredContacts
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((contact, index) => {
                            const {
                              _id,
                              name,
                              email,
                              country,
                              company,
                              phone,
                              lastestInteraction,
                            } = contact;
                            const isItemSelected =
                              selected.indexOf(name) !== -1;
                            console.log("contact", contact);

                            return (
                              <TableRow
                                sx={{ cursor: "pointer" }}
                                hover
                                key={index}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                                onClick={() => {
                                  handleClick(_id);
                                }}
                              >
                                <TableCell align="left">{name}</TableCell>
                                <TableCell align="left">{email}</TableCell>
                                <TableCell align="left">{company}</TableCell>
                                <TableCell align="left">{country}</TableCell>
                                <TableCell align="left">{phone}</TableCell>
                                {/* <TableCell align="left">
                                {lastestInteraction}
                              </TableCell> */}
                                <TableCell align="center">
                                  {/* <Button
                                  disabled={callDisability}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCall(e, phone, _id, name);
                                  }}
                                >
                                  {callDisability && callId === _id ? (
                                    <PhoneForwardedIcon />
                                  ) : (
                                    <PhoneIcon />
                                  )}
                                </Button> */}
                                  <Button
                                    color="primary"
                                    style={{ border: "none", outline: "none" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCall(phone, _id, name);
                                    }}
                                  >
                                    <PhoneIcon />
                                  </Button>
                                  <Button
                                    color="primary"
                                    style={{ border: "none", outline: "none" }}
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      showLogs(phone);
                                      setPhoneNumber(phone);
                                    }}
                                  >
                                    <InfoIcon />
                                  </Button>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSentMail(email);
                                    }}
                                  >
                                    <MailIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isLoading || isUserNotFound ? (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {isLoading ? (
                                <CircularProgress />
                              ) : (
                                <SearchNotFound searchQuery={filterName} />
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
            {/* <Box
            onClick={() => {
              navigate(`/contacts/createContact`);
            }}
            sx={{ position: "fixed", bottom: 16, right: 16 }}
          >
            <Fab color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </Box> */}
          </Grid>
        </Container>
        <Dialog open={agreeSetPasswordDialog} keepMounted onClose={handleClose}>
          <DialogTitle>{"Email Password Not Set"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              You haven't set a password for your email. Click 'AGREE' if you
              want to set one now.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              DISAGREE
            </Button>
            <Button onClick={handlePasswordAgree}>AGREE</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openCallDialouge.state} onClose={handleCloseCallDialouge}>
          <DialogTitle>Phone Number</DialogTitle>
          <DialogContent>
            <DialogContentText>
              It looks like this contact is missing a phone number. Click on
              SAVE to add one.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Phone Number"
              type="Number"
              fullWidth
              variant="standard"
              value={contactPhonNumber}
              onChange={handleChangePhone}
              placeholder="Ex: +919876543210"
              autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCallDialouge} color="error">
              CANCEL
            </Button>
            <Button
              onClick={() => {
                // handleCloseCallDialouge();
                updatePhoneNumber(openCallDialouge.contactId);
              }}
            >
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={fileUploadDialog || excelFileState.isExcelButtonClicked}
          onClose={() => {}}
        >
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FileUpload
                  files={files}
                  setFiles={setFiles}
                  currentUser={currentUser}
                  isFromSacnDoc={
                    !excelFileState.isExcelButtonClicked ? true : false
                  }
                  isForExcelFileUpload={excelFileState.isExcelButtonClicked}
                />
              </Grid>
              <Grid item xs={12}>
                <FileList
                  files={files}
                  setFiles={setFiles}
                  fromContactListPage={true}
                  removeFile={removeFile}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setFileUploadDialog((prev) => (prev = false));
                setFiles([]);
                setExcelFileState((prev) => ({
                  ...prev,
                  isExcelButtonClicked: false,
                }));
              }}
              color="error"
            >
              CANCEL
            </Button>
            <Button
              onClick={
                excelFileState.isExcelButtonClicked
                  ? uploadExcelFile
                  : handleFileInputChange
              }
            >
              SUBMIT
            </Button>
          </DialogActions>
        </Dialog>
        <LoaderDialouge loaderDialog={loaderDialog} />
        <Dialog open={popup} onClose={() => {}}>
          <DialogTitle textAlign="center">Define Interval</DialogTitle>
          <DialogContent>
            <Grid container spacing={2.5}>
              <Grid item xs={6} sm={6} sx={{ marginTop: "11px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} required>
                  <DesktopDatePicker
                    fullWidth
                    label="From"
                    inputFormat="MMM/dd/yyyy"
                    value={startDate}
                    maxDate={new Date().setDate(new Date().getDate() - 1)}
                    name="startDate"
                    onChange={handleStartDateChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6} sm={6} sx={{ marginTop: "11px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} required>
                  <DesktopDatePicker
                    required
                    fullWidth
                    label="To"
                    inputFormat="MMM/dd/yyyy"
                    // minDate={project.timeline.deadline}
                    // minDate={}
                    value={endDate}
                    name="endDate"
                    maxDate={new Date()}
                    onChange={handleEndDateChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPopup(() => false);
                setInterval(() => "");
                setIntervals(initialIntervals[3]);
                setStartDate(new Date().setDate(new Date().getDate() - 1));
              }}
              sx={{ color: "red" }}
            >
              CANCEL
            </Button>
            <Button onClick={handleSet}>SET</Button>
          </DialogActions>
        </Dialog>
      </Page>
    </>
  );
}
