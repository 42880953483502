import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { Grid, Typography, Box } from "@mui/material";

const data = [
  {
    id: "java",
    label: "java",
    value: 79,
    color: "hsl(198, 70%, 50%)",
  },
  {
    id: "haskell",
    label: "haskell",
    value: 596,
    color: "hsl(272, 70%, 50%)",
  },
  {
    id: "scala",
    label: "scala",
    value: 23,
    color: "hsl(153, 70%, 50%)",
  },
  {
    id: "ruby",
    label: "ruby",
    value: 515,
    color: "hsl(316, 70%, 50%)",
  },
  {
    id: "javascript",
    label: "javascript",
    value: 514,
    color: "hsl(334, 70%, 50%)",
  },
];
let status1 = [
  "Lead",
  "Prospect",
  "Opportunity",
  "Drop",
  "Close",
  "Opportunity",
  "Drop",
  "Close",
  "Prospect",
  "Opportunity",
];
const Nivopie = ({
  reportFigures,
  reportData,
  isCompared,
  status,
  countries,
  selectOwnerList,
  OwnersNameList,
  salesOwnerName,
  primaryComparetor,
}) => {
  let Total = Object.values(reportFigures?.updated)?.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const modifiedData = Object.keys(reportFigures?.updated)?.map((key) => ({
    id: key.charAt(0).toUpperCase() + key.slice(1),
    label: key.charAt(0).toUpperCase() + key.slice(1),
    value: reportFigures?.updated[key],
    color: `hsl(${Math.random() * 360}, 70%, 50%)`,
  }));

  const renderStatus = (listArray) => {
    if (listArray.length > 4) {
      if (listArray.length === 5) {
        const firstThree = listArray?.slice(0, 3);

        const additionalCount = listArray?.length - 3;

        return `${firstThree.join(",")} and ${additionalCount} others`;
      }
      const firstThree = listArray?.slice(0, 4);

      const additionalCount = listArray?.length - 4;

      return `${firstThree.join(",")} and ${additionalCount} others`;
    } else {
      // return listArray.join(", ");
      const listCopy = [...listArray];
      const lastElement = listCopy.pop();
      console.log(`${listCopy.join(", ")} and ${lastElement}`, "myelseee");
      return `${listCopy.join(", ")} and ${lastElement}`;
    }
  };
  const renderSalesOwners = (userIds, userInfo) => {
    const filteredUsers = userInfo?.filter((user) =>
      userIds.includes(user._id)
    );

    const userNames = filteredUsers?.map((user) => user?.name);

    if (userNames.length > 4) {
      if (userNames.length === 5) {
        const firstThree = userNames?.slice(0, 3);

        const additionalCount = userNames?.length - 3;

        return `${firstThree.join(",")} and ${additionalCount} others`;
      }
      const firstThree = userNames?.slice(0, 4);
      const additionalCount = userNames.length - 4;
      return `${firstThree.join(", ")} and ${additionalCount} others`;
    } else {
      // return userNames.join(", ");
      const listCopy = [...userNames];
      const lastElement = listCopy.pop();
      console.log(`${listCopy.join(", ")} and ${lastElement}`, "myelseee");
      return `${listCopy.join(", ")} and ${lastElement}`;
    }
  };

  return (
    <>
      {isCompared && (
        <Grid
          container
          sx={{ display: "flex", flexDirection: "column" }}
          spacing={0}
        >
          <Box sx={{ width: "100%", maxWidth: 800 }}>
            <Typography variant="caption" display="block" gutterBottom>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {" "}
                {primaryComparetor === "Sales Owner"
                  ? "Status"
                  : primaryComparetor === "Country"
                  ? "Sales Owner"
                  : "Country"}
              </span>{" "}
              :{" "}
              {primaryComparetor === "Sales Owner"
                ? renderStatus(status)
                : primaryComparetor === "Country"
                ? renderSalesOwners(selectOwnerList, OwnersNameList)
                : renderStatus(countries)}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", maxWidth: 500 }}>
            <Typography variant="caption" display="block" gutterBottom>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {" "}
                {primaryComparetor === "Sales Owner"
                  ? "Country"
                  : primaryComparetor === "Country"
                  ? "Status"
                  : "Sales Owner"}
              </span>{" "}
              :{" "}
              {primaryComparetor === "Sales Owner"
                ? renderStatus(countries)
                : primaryComparetor === "Country"
                ? renderStatus(status)
                : renderSalesOwners(selectOwnerList, OwnersNameList)}
            </Typography>
          </Box>
        </Grid>
      )}
      <div style={{ width: "100%", height: "50vh" }}>
        <div style={{ width: "100%", height: "100%", marginBottom: "-4px" }}>
          <ResponsivePie
            data={modifiedData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "ruby",
                },
                id: "dots",
              },
              {
                match: {
                  id: "c",
                },
                id: "dots",
              },
              {
                match: {
                  id: "go",
                },
                id: "dots",
              },
              {
                match: {
                  id: "close",
                },
                id: "dots",
              },
              {
                match: {
                  id: "scala",
                },
                id: "lines",
              },
              {
                match: {
                  id: "lisp",
                },
                id: "lines",
              },
              {
                match: {
                  id: "elixir",
                },
                id: "lines",
              },
              {
                match: {
                  id: "prospects",
                },
                id: "lines",
              },
            ]}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        </div>
        {salesOwnerName ? (
          <Grid container spacing={1}>
            <Grid xs={10} item>
              {" "}
              <Typography textAlign="start" fontWeight="300" color="#FF5733">
                <b>Name</b>: <b>{salesOwnerName}</b>
              </Typography>
            </Grid>
            <Grid xs={2} item>
              {" "}
              <Typography textAlign="end" fontWeight="300" color="#FF5733">
                <b>Total</b>: <b>{Total || 0}</b>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography textAlign="center" fontWeight="300" color="#FF5733">
            <b>Total</b>: <b>{Total || 0}</b>
          </Typography>
        )}
      </div>
    </>
  );
};

export default Nivopie;
