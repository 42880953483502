// scroll bar
import "simplebar/src/simplebar.css";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

//
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import AuthProvider from "./context/auth.context";
import ThemeProvider from "./theme";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getGoogleClient } from "./config";
import CallingProvider from "./context/calling.context";
import MailSyncProvider from "./context/mailSync.context";
import ActionAboutProvider from "./context/action.context";
import { DndProvider } from "react-dnd/dist";
import { HTML5Backend } from "react-dnd-html5-backend";

// ----------------------------------------------------------------------
const root = ReactDOM.createRoot(document.getElementById("root"));
let pathName = window.location.pathname;
const data = window.location;
// console.log("Path Index", data);
if (pathName === "/" || pathName === undefined) {
  pathName = "/dashboard";
  // console.log("Path Index2", pathName);
}

const clientId = getGoogleClient();
root.render(
  <Provider store={store}>
    <AuthProvider>
      <CallingProvider>
        <MailSyncProvider>
          <ActionAboutProvider>
            <GoogleOAuthProvider clientId={clientId}>
              <HelmetProvider>
                <BrowserRouter>
                  <ThemeProvider>
                    <DndProvider backend={HTML5Backend}>
                      <App pathName={pathName} />
                    </DndProvider>
                  </ThemeProvider>
                </BrowserRouter>
              </HelmetProvider>
            </GoogleOAuthProvider>
          </ActionAboutProvider>
        </MailSyncProvider>
      </CallingProvider>
    </AuthProvider>
  </Provider>
);

serviceWorker.unregister();

reportWebVitals();
