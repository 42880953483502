import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import Action from "./Action.View";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CardContent,
} from "@mui/material";
// components
import Page from "../../UI/Page";
import Label from "../../UI/Label";
import Scrollbar from "../../UI/Scrollbar";
import Iconify from "../../UI/Iconify";
import SearchNotFound from "../../SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../sections/@dashboard/user";
// mock
import USERLIST from "../../../_mock/user";
import CreateAction from "./Actions.Create";
import { getActions as getContactActions } from "../../../services/contacts.service";
import { getActions as getLeadActions } from "../../../services/leads.service";
import { getActions as getProjectActions } from "../../../services/projects.service";

import constants from "../../../constants";
import { getCompanyActions } from "../../../services/companies.service";
import { ActionContext } from "../../../context/action.context";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "ownedBy", label: "Owned By", alignRight: false },
  { id: "last", label: "Last", alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  // { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ActionSection({
  contactInfoObj,
  action,
  setAction,
  initialActionState,
  isEditPage,
  isProjectEditPage,
  setLoadedActions,
  loadedActions,
  actionType,
  id,
  leadId,
  projectId,
  techOwner,
  checked,
  setChecked,
  getAllDoers,
  currentUser,
  lead,
  fromContactPage,
  fromLeadPage,
  fromProjectPage,
  allDoers,
  fromCompanyViewPage,
  companyId,
  iactionedApiCalled,
  setIactionedApiCalled,
  storeTemplate,
  setStoreTemplate,
  setIsPriceNegotiation,
  storeCurrentTemplate,
  setStoreCurrentTemplate,
  selectedValue,
  setSelectedValue,
  salesOwner,
  state,
  content,
  setContent,
  previousContent,
  setPreviousContent,
}) {
  console.log("Loaded actions", loadedActions);

  const [count, setCount] = useState(1);
  const [page, setPage] = useState(0);

  const [pageCount, setPageCount] = useState(1);

  const [addedActionCounter, setAddedActionCounter] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [allEstimationStore, setEstimationOutlineStore] = useState([]);
  const [copiedValue, setCopiedValue] = useState(false);
  const [template, setTemplate] = useState("");
  const [fromCreateOne, setFromCreateOne] = useState(false);

  const { rowColor } = useContext(ActionContext);

  let obj = contactInfoObj || {};

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    // const selectedIndex = selected.indexOf(name);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    // console.log("Inside handle change page", newPage);
    // if (
    //   rowsPerPage * (parseInt(newPage) + 1) >
    //   loadedActions.length /*&&
    //   loadedComments.length < count*/
    // ) {
    //   console.log("Fetching new data");
    //   setPageCount((prevState) => prevState + 1);
    // }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  let filteredUsers;
  if (loadedActions.length > 0) {
    filteredUsers = applySortFilter(
      loadedActions,
      getComparator(order, orderBy),
      filterName
    );
  }

  function FetchActions(pageCount = 1) {
    if (actionType === constants.LEADS) {
      getLeadActions(id, leadId, pageCount)
        .then((response) => {
          console.log("Response of lead actions", response);
          if (response.data.status) {
            setLoadedActions((prevState) => [
              // ...prevState,
              ...response.data.data.leadActions,
            ]);
            console.log(loadedActions);
            setCount(response.data.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (actionType === constants.CONTACTS) {
      getContactActions(id, pageCount)
        .then((response) => {
          if (response.data.status) {
            console.log("loadedActions", response.data);
            setLoadedActions((prevState) => [
              ...prevState,
              ...response.data.data.allActions,
            ]);

            setCount(response.data.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (actionType === constants.PROJECTS) {
      getProjectActions(projectId, pageCount)
        .then((response) => {
          console.log("Response of project actions", response);
          if (response.data.status) {
            setLoadedActions((prevState) => [
              ...prevState,
              ...response.data.data.projectActions,
            ]);
            console.log(loadedActions);
            setCount(response.data.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (actionType === constants.COMPANIES) {
      getCompanyActions(companyId, pageCount)
        .then((response) => {
          if (response.data.status) {
            setLoadedActions(response.data.data.companyActions);
            setCount(response.data.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  function FetchActionWhen(pageCount = 1) {
    if (actionType === constants.LEADS) {
      getLeadActions(id, leadId, pageCount)
        .then((response) => {
          console.log("Response of lead actions", response);
          if (response.data.status) {
            setLoadedActions((prevState) => [
              // ...prevState,
              ...response.data.data.leadActions,
            ]);
            console.log(loadedActions);
            setCount(response.data.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (actionType === constants.CONTACTS) {
      getContactActions(id, pageCount)
        .then((response) => {
          if (response.data.status) {
            console.log("loadedActions", response.data);
            setLoadedActions((prevState) => [
              // ...prevState,
              ...response.data.data.allActions,
            ]);

            setCount(response.data.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (actionType === constants.PROJECTS) {
      getProjectActions(projectId, pageCount)
        .then((response) => {
          console.log("Response of project actions", response);
          if (response.data.status) {
            setLoadedActions((prevState) => [
              // ...prevState,
              ...response.data.data.projectActions,
            ]);
            console.log(loadedActions);
            setCount(response.data.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (actionType === constants.COMPANIES) {
      getCompanyActions(companyId, pageCount)
        .then((response) => {
          if (response.data.status) {
            setLoadedActions(response.data.data.companyActions);
            setCount(response.data.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  useEffect(() => {
    if (iactionedApiCalled) {
      console.log("iactionedApiCalled", iactionedApiCalled);
      FetchActionWhen(pageCount);
      setIactionedApiCalled(false);
    }
  }, [iactionedApiCalled]);
  console.log(copiedValue, "from actionsec");

  useEffect(() => {
    console.log("Use effect triggered");
    FetchActions(pageCount);
  }, [pageCount]);

  // const isUserNotFound = filteredUsers.length === 0;
  // const hasActions = obj && obj.actions && obj.actions.length > 0;

  if (loadedActions.length > 0 && state?.from === "DASHBOARD") {
    let index = loadedActions.findIndex((i) => state?.actionId === i._id);

    if (index !== -1) {
      let element = loadedActions.splice(index, 1)[0];
      loadedActions.unshift(element);
    }
  }

  return (
    <>
      <Card elevation={3} variant="outlined">
        <CardContent>
          <CreateAction
            // key={id}
            showButton={true}
            action={action}
            setAction={setAction}
            id={id}
            leadId={leadId}
            projectId={projectId}
            actionType={actionType}
            initialActionState={initialActionState}
            loadedActions={loadedActions}
            setLoadedActions={setLoadedActions}
            isEditPage={isEditPage}
            isProjectEditPage={isProjectEditPage}
            setPageCount={setPageCount}
            FetchActions={FetchActions}
            setPage={setPage}
            addedActionCounter={addedActionCounter}
            setAddedActionCounter={setAddedActionCounter}
            checked={checked}
            setChecked={setChecked}
            getAllDoers={getAllDoers}
            fromLeadPage={fromLeadPage}
            currentUser={currentUser}
            lead={lead}
            fromContactPage={fromContactPage}
            contact={contactInfoObj}
            fromProjectPage={fromProjectPage}
            allDoers={allDoers}
            techOwner={techOwner}
            fromCompanyViewPage={fromCompanyViewPage}
            companyId={companyId}
            salesOwner={salesOwner}
          />
          <Scrollbar>
            <TableContainer style={{ marginTop: "10px" }}>
              <Table>
                {loadedActions.length > 0 ? (
                  <TableBody>
                    {loadedActions
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        console.log(row, "rowwwww..");
                        const isItemSelected =
                          selected.indexOf(row.name) !== -1;

                        return (
                          <TableRow
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell
                              style={{
                                backgroundColor:
                                  row._id === state?.actionId ? rowColor : "",
                              }}
                            >
                              <Action
                                // key={id}
                                setLoadedActions={setLoadedActions}
                                setPageCount={setPageCount}
                                FetchActions={FetchActions}
                                actionType={actionType}
                                actionId={row._id}
                                id={id}
                                leadId={leadId}
                                projectId={projectId}
                                toDo={row.toDo}
                                isDone={row.isDone}
                                doneOn={row.doneOn}
                                doer={row.doer}
                                doerId={row.doer.doerId}
                                doneBy={row.doneBy}
                                dueDate={row.dueDate}
                                meetLink={row.meetLink}
                                isEstimationCreated={row?.isEstimationCreated}
                                isProposalCreated={row?.isProposalCreated}
                                // dueTime={row.dueTime}
                                assignedBy={row.assignedBy}
                                techOwner={techOwner}
                                contactInfoObj={contactInfoObj}
                                getAllDoers={getAllDoers}
                                isProjectEditPage={isProjectEditPage}
                                companyId={companyId}
                                fromLeadPage={fromLeadPage}
                                loadedActions={loadedActions}
                                storeTemplate={storeTemplate}
                                setStoreTemplate={setStoreTemplate}
                                copiedValue={copiedValue}
                                setCopiedValue={setCopiedValue}
                                template={template}
                                setTemplate={setTemplate}
                                fromCreateOne={fromCreateOne}
                                setFromCreateOne={setFromCreateOne}
                                setIsPriceNegotiation={setIsPriceNegotiation}
                                storeCurrentTemplate={storeCurrentTemplate}
                                setStoreCurrentTemplate={
                                  setStoreCurrentTemplate
                                }
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                                lead={lead}
                                content={content}
                                setContent={setContent}
                                previousContent={previousContent}
                                setPreviousContent={setPreviousContent}
                              ></Action>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        {/* <SearchNotFound searchQuery={filterName} /> */}
                        No actions available
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          {/* {hasActions && ( */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* )} */}
        </CardContent>
      </Card>
    </>
  );
}
