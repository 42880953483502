const apis = require("./api.requests");

const TAG = "leads.service";
const contactsPath = require("./contacts.service").path;
const path = "/leads/v1";
const commonPath = "/common/v1/";
async function getContactsForDrop() {
  return await apis.hitGetApi(`/Lead/v1/search`);
}

async function createLead(contactId, body) {
  return await apis.hitPostApi(`${contactsPath}${path}/${contactId}`, body);
}

async function getLeads(page, sortBySwitch) {
  return await apis.hitPagedApi(
    `${contactsPath}${path}/all`,
    page,
    sortBySwitch
  );
}

async function getContact(leadId) {
  return await apis.hitGetApi(`${contactsPath}${path}/{$leadId}`);
}

async function getLeadById(contactId, leadId) {
  return await apis.hitGetApi(
    `${contactsPath}${path}/${contactId}/${leadId}/details`
  );
}

async function updateLead(contactId, leadId, body) {
  return await apis.hitPutApi(
    `${contactsPath}${path}/${contactId}/${leadId}`,
    body
  );
}

async function removeLead(contactId, leadId) {
  return await apis.hitDeleteApi(
    `${contactsPath}/${contactId}/${path}/${leadId}`
  );
}

async function createComment(contactId, leadId, body) {
  console.log(contactId, leadId, body);
  const { comment, createdBy, cleanedContent } = body;
  let comments = {
    comment: cleanedContent,
    createdBy,
  };
  var formData = new FormData();
  let indexes = [];
  let descriptionFiles = [];
  formData.append("leadId", leadId);

  if (body.files && body.files.length > 0) {
    body.files.map((ele, index) => {
      console.log("ele", ele);
      if (ele.checked) {
        indexes.push(index);
      }
      if (ele.description && ele.description.length) {
        descriptionFiles.push({
          fileIndex: index,
          description: ele.description,
        });
      }
      formData.append("docFile", ele);
    });
  } else {
    formData.append("docFile", []);
    console.log("No files to process");
  }

  formData.append("checkedIndexes", JSON.stringify(indexes));
  formData.append("descriptionFiles", JSON.stringify(descriptionFiles));
  formData.append("comment", JSON.stringify(comments));
  return await apis.hitPostApi(
    `${contactsPath}${path}/${contactId}/${leadId}/comments`,
    formData
  );
}

async function getComments(contactId, leadId, page) {
  return await apis.hitPagedApi(
    `${contactsPath}${path}/${contactId}/${leadId}/comments`,
    page
  );
}

async function createAction(contactId, leadId, body) {
  return await apis.hitPostApi(
    `${contactsPath}${path}/${contactId}/${leadId}/actions`,
    body
  );
}

async function getActions(contactId, leadId, page) {
  return await apis.hitPagedApi(
    `${contactsPath}${path}/${contactId}/${leadId}/actions`,
    page
  );
}

async function createStatus(contactId, leadId, body) {
  return await apis.hitPostApi(
    `${contactsPath}/${contactId}/${path}/${leadId}/statuses`,
    body
  );
}

async function getStatuses(contactId, leadId, page) {
  return await apis.hitPagedApi(
    `${contactsPath}/${contactId}/${path}/${leadId}/statuses`,
    page
  );
}

async function setLeadAction(contactId, leadId, actionId, date) {
  return await apis.hitPostApi(
    `${contactsPath}${path}/${contactId}/${leadId}/actions/${actionId}/markAsDone`,
    date
  );
}

async function deleteDocument(contactId, leadId, data) {
  return await apis.hitDeleteApi(
    `${contactsPath}${path}/${contactId}/${leadId}/${data}/deleteDoc`
  );
}

// File Upload
async function uploadDocument(contactId, leadId, files) {
  var formData = new FormData();
  formData.append("leadId", leadId);
  let indexes = [];
  let descriptionFiles = [];
  files.map((ele, index) => {
    if (ele.checked) {
      indexes.push(index);
    }
    if (ele.description && ele.description.length) {
      descriptionFiles.push({
        fileIndex: index,
        description: ele.description,
      });
    }
    formData.append("docFile", ele);
  });
  formData.append("checkedIndexes", JSON.stringify(indexes));
  // console.log(descriptionFiles);
  formData.append("descriptionFiles", JSON.stringify(descriptionFiles));

  return await apis.hitPostApi(
    `${contactsPath}${path}/${contactId}/uploadDocs`,
    formData
  );
}
async function getLeadsSearch(key, value) {
  return await apis.hitGetApi(
    // `contacts/${path}/all/search?searchKey=${key}&searchValue=${value}`
    `/contacts/v1${path}/all/search?searchKey=${
      key ? key : ""
    }&searchValue=${encodeURIComponent(value ? value : "")}`
  );
}

//update watcher api implement

async function updateWatcher(contactId, leadId, data) {
  return await apis.hitPutApi(
    `${contactsPath}${path}/${contactId}/${leadId}/createWatcher`,
    data
  );
}

{
  /* localhost: 3022 / api / contacts / v1 / leads / v1 / 62fcb2a555f52dc43339e97f
 / 62fcb2a555f52dc43339e983 / getActionDoersByLeadId
/* By This API Call we get the actions doers whose isDone carries false */
}
async function getDoersByLeadId(contactId, leadId) {
  return await apis.hitGetApi(
    `${contactsPath}${path}/${contactId}/${leadId}/getActionDoersByLeadId`
  );
}

async function createPriceNegotiation(contactId, leadId, body, files) {
  if (files && files.length) {
    const formData = new FormData();
    formData.append("priceInformations", JSON.stringify(body));
    files.forEach((file) => {
      formData.append("priceFiles", file);
    });
    return await apis.hitPostApiWithFormData(
      `${contactsPath}${path}/priceNegotiation/${contactId}/${leadId}`,
      formData
    );
  } else {
    return await apis.hitPostApi(
      `${contactsPath}${path}/priceNegotiation/${contactId}/${leadId}`,
      body
    );
  }
}

async function getPriceNegociation(contactId, leadId, date) {
  return await apis.hitGetApi(
    `${contactsPath}${path}/getPriceNegotiation/${contactId}/${leadId}/${date}`
  );
}

async function getAllResourceDescription() {
  return await apis.hitGetApi(`${contactsPath}${path}/getSourceDescription`);
}

async function updatePriceNegotiation(
  contactId,
  leadId,
  priceNegotiationId,
  data
) {
  return await apis.hitPutApi(
    `${contactsPath}${path}/updatePriceNegotiationUpdate/${contactId}/${leadId}/${priceNegotiationId}`,
    data
  );
}

async function deletePriceNegotiation(contactId, leadId, priceNegotiationId) {
  return await apis.hitDeleteApi(
    `${contactsPath}${path}/deletePriceNegotiation/${contactId}/${leadId}/${priceNegotiationId}`
  );
}

async function getAllContactEmail() {
  return await apis.hitGetApi(
    `${commonPath}/getContactEmails?contactEmail=suvendu@utkallabs.com`
  );
}

async function createEmailAssociate(contactId, leadId, body) {
  console.log("body", body);
  delete body.state;
  if (body.attachments && body.attachments.length) {
    body["checkedIndex"] = [];
    body["fileDescriptions"] = [];
    body.attachments.forEach((element, index) => {
      if (element.checked) {
        body["checkedIndex"].push(index);
      }
      console.log("element", element);
      console.log("element desc", element.OneLinerDescription);
      if (element.OneLinerDescription && element.OneLinerDescription.length) {
        console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii");
        body["fileDescriptions"].push({
          fileIndex: index,
          description: element.OneLinerDescription,
        });
      }
    });
  }
  return await apis.hitPostApi(
    `${contactsPath}${path}/${contactId}/${leadId}/email`,
    body
  );
}

// async function createEstimation(data) {
//   return await apis.hitPostApi(`${path}/estimation`, data);
// }
// async function getEstimationById(estimationId) {
//   return await apis.hitGetApi(`${path}/estimation/${estimationId}`);
// }

// async function updateEstimatebyId(estimationId, data) {
//   return await apis.hitPutApi(`${path}/estimation/${estimationId}`, data);
// }

export {
  createLead,
  getLeads,
  getLeadById,
  updateLead,
  removeLead,
  createComment,
  getComments,
  createAction,
  getActions,
  createStatus,
  getStatuses,
  setLeadAction,
  uploadDocument,
  deleteDocument,
  getLeadsSearch,
  getContactsForDrop,
  getContact,
  updateWatcher,
  getDoersByLeadId,
  createPriceNegotiation,
  getPriceNegociation,
  getAllResourceDescription,
  updatePriceNegotiation,
  deletePriceNegotiation,
  getAllContactEmail,
  createEmailAssociate,
  // createEstimation,
  // getEstimationById,
  // updateEstimatebyId,
};
