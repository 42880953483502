import React, { useState, useEffect, useContext } from "react";
import "./LeadViewPage.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LeadInfo from "../../components/Lead/Lead.view";

import CircularProgress from "@mui/material/CircularProgress";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import MailIcon from "@mui/icons-material/Mail";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { getPassword } from "../../utils/indexedDb";
import {
  getLeadById,
  uploadDocument,
  updateWatcher,
  getDoersByLeadId,
  createEmailAssociate,
} from "../../services/leads.service";
import {
  Grid,
  Card,
  Box,
  Button,
  Typography,
  Container,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tooltip,
  Select,
  InputLabel,
  FormControl,
  Divider,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import Page from "../../components/UI/Page";
import Info from "../../components/Contacts/Info";

import ActionSection from "../../components/Shared/Action/ActionSection";
import CommentSection from "../../components/Shared/Comments/CommentSection";
import { ToastContainer, toast } from "react-toastify";
import constants from "../../constants";
import FileUpload from "../../components/Fileupload";
import AddIcon from "@mui/icons-material/Add";
import FileList from "../../components/FileList";
import { AuthContext } from "../../context/auth.context";
import { getContactAllLeads } from "../../services/contacts.service";
import { getComments as getLeadComments } from "../../services/leads.service";
import Watcher from "../../components/Watcher";
import { getUserById, getUsersByRoles } from "../../services/users.service";
// import { getUsersByRoles } from "../../services/users.service";
import PriceNegociationSection from "./PriceNegociationSection";
import { getMailsByEmail, updateEmail } from "../../utils/indexedDb";
import { useTheme } from "@mui/system";
import DOMPurify from "dompurify";
import AssociateEmailPrivate from "../../components/AssociateEmails/AssociateEmailPrivate";
import handleButtonByRoleAccess from "../../utils/handleButttonByRoleAccess";
import { useDynamicLeadTitle } from "./Leads";
import { useDynamicContactTitle } from "../Contacts/Contacts";

const TAG = "LeadViewPage.js";
let contactIdLeadId = {};
const LeadViewPage = () => {
  const leadDynamicTitel = useDynamicLeadTitle();
  const contactDynamicTitle = useDynamicContactTitle();
  const { currentUser } = useContext(AuthContext);
  const { id, contactId } = useParams();

  const navigate = useNavigate();
  const { state } = useLocation();
  const [contactInfoObj, setContactInfoObj] = useState({});
  const [count, setCount] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [customAttributes, setCustomAttributes] = useState([]);
  const [contactArributes, setContactAttributes] = useState([]);
  // const [storeTemplate, setStoreTemplate] = useState([]);
  const [storeTemplate, setStoreTemplate] = useState(null);
  const [storeCurrentTemplate, setStoreCurrentTemplate] = useState(null);
  const [selectedValue, setSelectedValue] = React.useState({});
  const [isPriceNegotiation, setIsPriceNegotiation] = useState(false);
  const [content, setContent] = useState("");
  const [previousContent, setPreviousContent] = useState("");
  const initialContactState = {
    name: "",
    company: "",
    email: "",
    phone: "",
    alternateContact: "",
    address: "",
    country: "",
    linkedIn: "",
    source: {
      name: "",
    },
    salesOwner: "",
  };

  let initialLeadState = {
    summary: "",
    skills: [],
    status: "lead",
    isProjectCreated: false,
    description: "",
    techOwner: "",
    documents: [],
    name: "",
    company: "",
    email: "",
    phone: "",
    alternateContact: "",
    address: "",
    country: "",
    linkedIn: "",
    source: {
      name: "",
    },
    salesOwner: "",
    reason: "",
    watchers: [],
    attributes: [],
  };
  //TODO: Remove the curly braces
  const [lead, setLead] = React.useState(initialLeadState);
  const [leadData, setLeadData] = useState([]);
  const [leadStatus, setLeadStatus] = React.useState("");

  const [toEdit, setToEdit] = React.useState(true);
  const [homeCompany, setHomeCompany] = React.useState("");
  const [homeAccount, setHomeAccount] = React.useState("");
  const [isViewDocuments, setIsViewDocuments] = useState(false);
  //Cost Asssignment States And funtions
  const [costAssignmentState, setCostAssignmentState] = useState({
    open: false,
    onClose: () => {
      setCostAssignmentState((prev) => ({
        ...prev,
        open: false,
        mode: "",
        totalCost: "",
        gstIncluded: false,
        numberofResources: "",

        account: "",
        company: "",
        currency: "",
        revenues: [],
        tmRevenues: [],
      }));
      setProjectCustomAttributes([]);
      setProjectSkills([]);
      setProjectTechStacks([]);
      setProjectData((prev) => ({
        ...prev,
        title: "",
        specification: "",
        details: "",
      }));
    },
    mode: "",
    totalCost: "",
    gstIncluded: false,
    gstApplicable: false,
    numberofResources: "",
    currency: "",
    revenues: [],
    tmRevenues: [],
    account: "",
    company: "",

    handleRevinew: (times) => {
      const data = [];
      for (let index = 0; index < times; index++) {
        data.push({
          paymentCycle: "",
          resourceName: "",
          cost: "",
          invoiceDescription: "",
        });
      }
      setCostAssignmentState((prev) => ({ ...prev, tmRevenues: [...data] }));
    },
    onModesChange: () => {
      setCostAssignmentState((prev) => ({
        ...prev,
        totalCost: "",
        gstIncluded: false,
        numberofResources: "",
        account: "",
        company: "",
        currency: "",
        revenues: [],
        tmRevenues: [],
      }));
    },
    insertRevenueOneByOne: () => {
      setCostAssignmentState((prev) => {
        if (prev.revenues.length < 20) {
          const temp = { ...prev };
          temp.revenues.push({
            milestoneName: "",
            cost: "",
            invoiceDescription: "",
          });

          return temp;
        } else {
          toast.warn("You cannot add more than 20 items.");
          return prev;
        }
        // const temp = { ...prev };
        // temp.revenues.push({
        //   milestoneName: "",
        //   cost: "",
        //   invoiceDescription: "",
        // });
        // return temp;
      });
    },
    removeRevenues: (allRevenues, index) => {
      const tempRevenues = [...allRevenues];
      tempRevenues.splice(index, 1);
      setCostAssignmentState((prev) => ({
        ...prev,
        revenues: [...tempRevenues],
      }));
    },
    handleIncludeGst: (event) => {
      setCostAssignmentState((prev) => ({
        ...prev,
        gstIncluded: event.target.checked,
      }));
    },
  });
  //projectSatic attribute component usestate
  const [projectSkills, setProjectSkills] = useState([]);
  const [projectTechStacks, setProjectTechStacks] = useState([]);
  const [projectCustomAttributes, setProjectCustomAttributes] = useState([]);
  const [projectData, setProjectData] = useState({
    title: "",
    specification: "",
    details: "",
  });

  const initialActionState = {
    toDo: "",
    doer: "",
    assignedBy: currentUser._id,
    dueDate: new Date(),
    meetLink: "",
    // dueTime: new Date(),
  };
  const [action, setAction] = React.useState(initialActionState);

  const [isEditPage] = React.useState(true);

  const [TempLead, setTempLead] = React.useState(initialLeadState);
  const [TempContact, setTempContact] = React.useState(initialContactState);
  const initialCommentState = {
    comment: "",
    createdBy: "",
    createdAt: "",
  };
  const [contactMails, setContactMails] = useState([]);

  const [comment, setComment] = React.useState(initialCommentState);
  const [contact, setContact] = React.useState(initialContactState);
  const [loadedComments, setLoadedComments] = useState([]);
  const [loadedActions, setLoadedActions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = useState([]);
  const [saveButton, setSaveButton] = useState(false);
  const [loaderDialog, setLoaderDialog] = React.useState(false);
  const [watcherDialog, setWatcherDialog] = useState(false);
  const [isWatcherDialogOnEdit, setIsWatcherDialogOnEdit] = useState(false);
  const [users, setUsers] = useState([]);

  const [customEmails, setCustomEmails] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [updateLeadCalled, setUpdateLeadCalled] = useState(false);
  const [leadMailDialog, setLeadMailDialog] = useState(false);
  const [salesOwnerTechOwnerInfo, setsalesOwnerTechOwnerInfo] = useState({
    salesOwnerName: "",
    techOwnerName: "",
  });

  const [allDoers, setAllDoers] = useState([]);
  const [filterDone, setfilterDone] = useState(false);
  const [selectData, setSelectData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [docOpen, setDocOpen] = React.useState(false);

  //Description Object
  const [descriptionObj, setDescriptionObj] = useState({});
  const handleClickOpen = (fromViewDoc) => {
    setOpen((prev) => (prev = true));
    setIsViewDocuments(fromViewDoc);
  };
  const handleDocClickOpen = () => {
    setDocOpen((prev) => (prev = true));
  };
  const [asscoateEmailListDialog, setAssociateEmailListDialog] = useState({
    state: false,
    attachments: [],
  });
  const [fullEmailView, setFullEmailView] = useState({
    state: false,
    from: "",
    to: "",
    subject: "",
    date: "",
    html: "",
  });
  const [openEmail, setOpenEmail] = useState(false);

  const [isAssociateDone, setIsAssociateDone] = useState(false);
  const [iactionedApiCalled, setIactionedApiCalled] = useState(false);
  const handleClose = () => {
    setFiles((prev) => {
      return isViewDocuments ? prev : (prev = []);
    });
    setOpen(false);
    setIsViewDocuments(false);
    setChecked(false);
    setAssociateEmailListDialog((prev) => {
      const temp = { ...prev };
      temp.state = false;
      return temp;
    });
    setDescriptionObj((prev) => (prev = {}));
  };
  const handleDocClose = () => {
    setFiles((prev) => {
      return (prev = []);
    });
    setDocOpen(false);
    setChecked(false);
    setAssociateEmailListDialog((prev) => {
      const temp = { ...prev };
      temp.state = false;
      return temp;
    });
    setDescriptionObj((prev) => (prev = {}));
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const submitFile = async () => {
    Object.keys(descriptionObj).forEach((eleInd) => {
      files[parseInt(eleInd)].description =
        descriptionObj[parseInt(eleInd)].trim();
    });
    try {
      setSaveButton(true);
      setLoaderDialog(true);
      console.log(files);
      const response = await uploadDocument(contactId, id, files);
      console.log("Response file upload", response);
      if (response && response.data.status) {
        setDescriptionObj({});
        setUpdateLeadCalled(false);
        setIactionedApiCalled(true);
        toast("Documents uploaded sucessfully");

        setSaveButton(false);
        setLoaderDialog(false);
        handleDocClose();
      }
    } catch (error) {
      console.log(error);
      setDescriptionObj({});
      setSaveButton(false);
      setLoaderDialog(false);
    }
  };
  const submitDocComment = async () => {
    // setFromCommentCreate(false);
    setOpen(false);
  };
  console.log(costAssignmentState, "cost estimate");
  useEffect(() => {
    getLeadById(contactId, id)
      .then((response) => {
        if (response.data.status) {
          console.log("Hi", response.data);
          const {
            company: { country },
          } = response.data.data[0];
          if (country !== null && country?.toLowerCase() === "india") {
            setCostAssignmentState((prev) => ({
              ...prev,
              gstApplicable: true,
            }));
          }
          setCustomAttributes(
            JSON.parse(JSON.stringify(response.data.data[0].attributes))
          );
          setContactAttributes(response.data.data[0].contactAttributes);
          setContactInfoObj(response.data.data);
          const keys = Object.keys(initialLeadState);
          const temp = {};
          keys.forEach((key) => {
            temp[key] = response.data.data[0][key];
          });
          getSalesOwnerInfo(temp.salesOwner);
          getTechOwnerInfo(temp.techOwner);

          console.log("temp.skills-->", temp);

          setLead(temp);
          setTempLead(temp);
          setLeadStatus(JSON.parse(JSON.stringify(lead.status)));

          setContact((prev) => ({
            ...prev,
            name: temp.name,
            company:
              typeof temp.company === "object"
                ? temp?.company?._id
                : temp.company,
            email: temp.email,
            phone: temp.phone,
            alternateContact: temp.alternateContact,
            address: temp.address,
            country: temp.country,
            linkedIn: temp.linkedIn,
            source: { name: temp.source },
            salesOwner: temp.salesOwner,
          }));

          console.log("Getleadbyid", lead);
        } else {
          navigate(`/unauthorized`);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data.code == 401) {
          navigate(`/unauthorized`);
        }
      });
    getAllUsers();
    // }, [files]);
  }, []);

  useEffect(() => {
    if (!updateLeadCalled) {
      getLeadById(contactId, id)
        .then((response) => {
          if (response.data.status) {
            console.log("Hi", response.data);
            const {
              company: { country },
            } = response.data.data[0];
            if (country !== null && country?.toLowerCase() === "india") {
              setCostAssignmentState((prev) => ({
                ...prev,
                gstApplicable: true,
              }));
            }
            setContactInfoObj(response.data.data);
            setCustomAttributes(
              JSON.parse(JSON.stringify(response.data.data[0].attributes))
            );
            setContactAttributes(response.data.data[0].contactAttributes);
            const keys = Object.keys(initialLeadState);
            const temp = {};
            keys.forEach((key) => {
              temp[key] = response.data.data[0][key];
            });
            getSalesOwnerInfo(temp.salesOwner);
            getTechOwnerInfo(temp.techOwner);

            console.log("temp.skills-->", temp);

            setLead(temp);
            setTempLead(temp);
            setLeadStatus(response.data.data[0].status);

            setContact((prev) => ({
              ...prev,
              name: temp.name,
              company:
                typeof temp.company === "object"
                  ? temp.company?._id || ""
                  : temp.company,
              email: temp.email,
              phone: temp.phone,
              alternateContact: temp.alternateContact,
              address: temp.address,
              country: temp.country,
              linkedIn: temp.linkedIn,
              source: { name: temp.source },
              salesOwner: temp.salesOwner,
            }));

            console.log("Getleadbyid", lead);
            setUpdateLeadCalled(true);
          } else {
            navigate(`/unauthorized`);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response?.data.code == 401) {
            navigate(`/unauthorized`);
          }
        });
      getAllUsers();
      getAllActionDoersByLeadId();
    }
  }, [updateLeadCalled]);

  console.log(leadStatus, "mysts");

  console.log(homeCompany, homeAccount, "homedetails");

  const getAllUsers = async () => {
    try {
      const response = await getUsersByRoles(1);
      if (response.data.status) {
        setUsers(response.data.data);
        setfilterDone(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTechOwnerInfo = async (id) => {
    try {
      const response = await getUserById(id, false);
      if (response.data.status) {
        setsalesOwnerTechOwnerInfo((prev) => ({
          ...prev,
          techOwnerName: response.data.data.name,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSalesOwnerInfo = async (id) => {
    try {
      const response = await getUserById(id, false);
      if (response.data.status) {
        setsalesOwnerTechOwnerInfo((prev) => ({
          ...prev,
          salesOwnerName: response.data.data.name,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(salesOwnerTechOwnerInfo);

  const getAllActionDoersByLeadId = async () => {
    try {
      const response = await getDoersByLeadId(contactId, id);
      if (response.data.status) {
        // getAllUsers();
        setAllDoers(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filter = () => {
    console.log(allDoers);

    const temp = [...users];

    if (temp.length > 0) {
      for (let index = 0; index < temp.length; index++) {
        for (let index1 = 0; index1 < allDoers.length; index1++) {
          if (temp[index] && allDoers[index1]) {
            if (temp[index]._id === allDoers[index1]._id) {
              temp.splice(index, 1);
            }
          } else {
            return;
          }
        }
      }
    }
    setUsers(temp);
  };

  useEffect(() => {
    getAllUsers();
  }, [allDoers]);

  const closeWatcherDialog = () => {
    setWatcherDialog(false);
  };

  function postUsersForWatcher() {
    let arr = [];
    lead.watchers.map((e, i) => {
      arr.push({ platformUser: e._id });
    });
    return arr;
  }

  const convertAgainToReadOnly = () => {
    setIsWatcherDialogOnEdit(false);
  };

  const CheckAndGetChangedLeadDataWatchers = () => {
    console.log(
      "-----> Lead Watcher",
      lead.watchers,
      "----->templead watchers",
      TempLead.watchers
    );
    const result =
      JSON.stringify(lead.watchers) === JSON.stringify(TempLead.watchers)
        ? false
        : true;
    return result;
  };

  const updateWatchers = async () => {
    console.log("first watcher call");
    try {
      const isChanged = CheckAndGetChangedLeadDataWatchers();
      console.log("isChnage---->350", isChanged);
      if (lead.watchers.length) {
        if (isChanged) {
          const response = await updateWatcher(contactId, id, {
            watchers: postUsersForWatcher(),
          });
          if (response.data.status) {
            closeWatcherDialog();
            toast("Watcher Updated Successfully");
            convertAgainToReadOnly();
            setUpdateLeadCalled(false);
            setIactionedApiCalled((prev) => !prev);
          }
        } else {
          toast("Nothing to update");
        }
      } else {
        if (isChanged) {
          const response = await updateWatcher(contactId, id, {
            watchers: postUsersForWatcher(),
          });
          if (response.data.status) {
            closeWatcherDialog();
            toast("Watcher Removed Successfully");
            convertAgainToReadOnly();
            setUpdateLeadCalled(false);
            setIactionedApiCalled((prev) => !prev);
          }
        } else {
          toast("Nothing to update");
        }
      }
    } catch (error) {
      toast("Something Went Wrong");
      console.log(error);
    }
  };

  const handleLeadMail = async () => {
    setIsLoading(true);
    const getContactAllLead = await getContactAllLeads(contactId);
    let response = getContactAllLead.data.data;
    setLeadData(response);
    const result = await getMailsByEmail(contact.email);
    if (result) {
      setIsLoading(false);
    }
    console.log("result", result);
    let ival = [];
    result.map((item) => {
      if (item.status === null) {
        setSelectData((prev) => {
          if (!prev) {
            prev = {};
          }
          prev[item.hash.toString()] = "";
          return prev;
        });

        ival.push(item);
      }
    });
    setContactMails(ival);
    setLeadMailDialog(true);
  };

  const closeHandleLeadMail = (contactId, index1) => {
    setLeadMailDialog(false);
  };
  const handelEmailView = (index) => {
    console.log("onmouse hover", index);
    setFullEmailView((prev) => ({
      ...prev,
      state: true,
      // attachments: contactMails[index].attachments,
      from: contactMails[index].from,
      to: contactMails[index].to,
      subject: contactMails[index].subject,
      date: contactMails[index].date,
      html: contactMails[index].html,
    }));
    setOpenEmail(true);
  };

  const handleCloseEmailView = () => {
    setOpenEmail(false);
  };

  const handleChange = (e, index, hash) => {
    console.log("e.target.value", e.target.value);
    // setSelectData(e.target.value)
    setSelectData((prev) => {
      prev[hash.toString()] = e.target.value;
      return prev;
    });

    console.log(`${TAG}.handle change select`, e.target.value, index);
    setContactMails((prev) => {
      const temp = [...prev];
      console.log(`${TAG}.handle change select temp value`, temp);
      temp[index].leadId = e.target.value;
      console.log(`${TAG}.handle change select addlead id temp value`, temp);
      return temp;
    });
  };

  const filterFiles = (struct, attachments) => {
    attachments = attachments || [];
    for (var i = 0, len = struct.length; i < len; i++) {
      console.log(struct[i]);
      console.log("index iterate", i);
      if (Array.isArray(struct[i])) {
        filterFiles(struct[i], attachments);
      } else {
        if (
          struct[i].disposition &&
          struct[i].disposition.type === "attachment"
        ) {
          struct[i].checked = false;
          attachments.push(struct[i]);
        }
      }
    }
    return attachments;
  };

  const handleAssociate = async (contactId, index1) => {
    if (
      contactMails[index1].leadId === "" ||
      contactMails[index1].leadId === null ||
      contactMails[index1].leadId === undefined
    ) {
      toast(`Select a ${leadDynamicTitel?.displayName.singularName}`);
      return;
    }
    const password = await getPassword();
    if (contactMails[index1].attachments.struct.length > 0) {
      const tempAssociateFiles = filterFiles(
        contactMails[index1].attachments.struct
      );
      console.log("tempAssociateFiles", tempAssociateFiles);
      if (tempAssociateFiles.length > 0) {
        setAssociateEmailListDialog((prev) => {
          const temp = { ...prev };
          temp.state = true;
          temp.attachments = [...tempAssociateFiles];
          temp["uid"] = contactMails[index1].attachments.uid;
          temp["html"] = contactMails[index1].html;
          temp["email"] = currentUser.email;
          temp["encrypt"] = password;
          return temp;
        });
        contactIdLeadId["contactId"] = contactId;
        contactIdLeadId["leadId"] = contactMails[index1].leadId;
        contactIdLeadId["index1"] = index1;
      } else {
        const body = {
          // attachments: contactMails[index1].attachments,
          html: contactMails[index1].html,
          email: currentUser.email,
          encrypt: password,
        };
        setLoaderDialog((prev) => (prev = true));
        const response = await createEmailAssociate(
          contactId,
          contactMails[index1].leadId,
          body
        );
        console.log("response", response);
        // setSelectData('')
        if (response) {
          setIsAssociateDone(false);
          setContactMails(() => {
            const temp = [...contactMails];
            console.log(`${TAG}.temp.before`, temp);
            if (temp.includes(contactMails[index1])) {
              const tempIndex = temp.indexOf(contactMails[index1]);
              temp.splice(tempIndex, 1);
              console.log(`${TAG}.temp.after`, temp);
            }
            (async () => {
              const key = "ASSOCIATE";
              return await updateEmail(contactMails[index1], key);
            })();

            console.log(`${TAG}.temp value.contact mails.temp`, temp);
            return temp;
          });

          setLoaderDialog(false);
        }
        setLoaderDialog(false);
        setIactionedApiCalled(true);
        toast("Email associated sucessfully");
      }
    }
  };
  const emailDocsUpload = async (e, asscoateEmailListDialog) => {
    setLoaderDialog((prev) => (prev = true));
    console.log(descriptionObj);
    Object.keys(descriptionObj).map((ele) => {
      asscoateEmailListDialog.attachments[parseInt(ele)][
        "OneLinerDescription"
      ] = descriptionObj[parseInt(ele)].trim();
    });
    const response = await createEmailAssociate(
      contactIdLeadId.contactId,
      contactIdLeadId.leadId,
      asscoateEmailListDialog
    );
    if (response) {
      setIsAssociateDone(false);
      setContactMails(() => {
        const temp = [...contactMails];
        console.log(`${TAG}.temp.before`, temp);
        if (temp.includes(contactMails[contactIdLeadId["index1"]])) {
          const tempIndex = temp.indexOf(
            contactMails[contactIdLeadId["index1"]]
          );
          temp.splice(tempIndex, 1);
          console.log(`${TAG}.temp.after`, temp);
        }
        (async () => {
          const key = "ASSOCIATE";
          return await updateEmail(
            contactMails[contactIdLeadId["index1"]],
            key
          );
        })();

        console.log(`${TAG}.temp valu.contact mails.temp`, temp);
        return temp;
      });

      setIactionedApiCalled(true);
      toast("Email associated sucessfully");
      setLoaderDialog((prev) => (prev = false));
    }
  };
  const handleDisCard = (contactId, index1) => {
    setContactMails(() => {
      const temp = [...contactMails];
      if (temp.includes(contactMails[index1])) {
        const tempIndex = temp.indexOf(contactMails[index1]);
        temp.splice(tempIndex, 1);
      }
      (async () => {
        const key = "DISCARD";
        return await updateEmail(contactMails[index1], key);
      })();

      return temp;
    });

    toast("Email Discarded Sucessfully");
  };

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(fullEmailView ? fullEmailView.html : null),
  });

  console.log(`${TAG}.contactMails`, contactMails);

  const getAssociatedDoucment = async () => {
    try {
      const response = await getLeadById(contactId, id);
      if (response.data.status) {
        console.log("Hi", response.data);
        setContactInfoObj(response.data.data);
        const keys = Object.keys(initialLeadState);
        setLead((prev) => {
          keys.forEach((key) => {
            prev[key] = response.data.data[0][key];
          });
          return prev;
        });
        console.log("Getleadbyid", lead);
      } else {
        navigate(`/unauthorized`);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.code == 401) {
        navigate(`/unauthorized`);
      }
    }
  };
  const FetchComments = async (pageCount = 1) => {
    if (constants.LEADS) {
      try {
        const response = await getLeadComments(contactId, id, pageCount);
        if (response.data.status) {
          console.log("response.data", response.data.data);
          setLoadedComments([...response.data.data.leadComments]);
          console.log("loaded", loadedComments);
          setCount(response.data.data.count);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    console.log("Use effect loadedComments", loadedComments);
  }, [pageCount]);

  useEffect(() => {
    if (!isAssociateDone) {
      getAssociatedDoucment();
      FetchComments();
      setIsAssociateDone(true);
    }
  }, [isAssociateDone]);

  return (
    <Page>
      <Container>
        <ToastContainer />
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={11} sm={11}>
                    <Typography variant="h5" gutterBottom color="primary">
                      {`${leadDynamicTitel?.displayName.singularName.toUpperCase()} DETAILS`}
                    </Typography>
                  </Grid>
                  <Grid item xs={0.5}>
                    {currentUser.role !== "DELIVERY_LEAD" ? (
                      <Tooltip
                        title={`You Can See All mails from:${contact.name}  Here `}
                      >
                        <IconButton color="primary" onClick={handleLeadMail}>
                          <MailIcon
                            color="primary"
                            sx={{ cursor: "pointer" }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Grid>
                  <Grid item xs={0.5}>
                    <Tooltip title="You Can Simply Add Watchers Here">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setWatcherDialog(true);
                          filter();
                        }}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {/* Lead Details */}
                    <LeadInfo
                      toEdit={toEdit}
                      setToEdit={setToEdit}
                      setHomeCompany={setHomeCompany}
                      setHomeAccount={setHomeAccount}
                      homeCompany={homeCompany}
                      homeAccount={homeAccount}
                      id={contactId}
                      leadId={id}
                      isEditPage={isEditPage}
                      lead={lead}
                      setLead={setLead}
                      leadStatus={leadStatus}
                      setLeadStatus={setLeadStatus}
                      TempLead={TempLead}
                      setTempLead={setTempLead}
                      setUpdateLeadCalled={setUpdateLeadCalled}
                      contact={contact}
                      callGetSalesOwnerInfoMethod={() =>
                        getSalesOwnerInfo(lead.salesOwner)
                      }
                      customAttributes={customAttributes}
                      setCustomAttributes={setCustomAttributes}
                      setIactionedApiCalled={setIactionedApiCalled}
                      costAssignmentDependency={setCostAssignmentState}
                      handleIncludeGst={costAssignmentState.handleIncludeGst}
                      costAssignment={costAssignmentState}
                      contactInfoObj={contactInfoObj}
                      projectSkills={projectSkills}
                      setProjectSkills={setProjectSkills}
                      projectTechStacks={projectTechStacks}
                      setProjectTechStacks={setProjectTechStacks}
                      projectData={projectData}
                      setProjectData={setProjectData}
                      projectCustomAttributes={projectCustomAttributes}
                      setProjectCustomAttributes={setProjectCustomAttributes}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      {`${contactDynamicTitle?.displayName.singularName.toUpperCase()} DETAILS`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {/* Lead Details */}
                    <Info
                      contact={contact}
                      setContact={setContact}
                      toEdit={true}
                      setToEdit={setToEdit}
                      id={contactId}
                      canEdit={false}
                      isEditPage={false}
                      TempContact={TempContact}
                      fromLeadViewPage={true}
                      setTempContact={setTempContact}
                      customAttributes={contactArributes}
                      setCustomAttributes={setContactAttributes}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* Price negotiation */}
          {currentUser.role === "ADMIN" ||
          currentUser._id === lead.salesOwner ? (
            // || currentUser._id === lead.techOwner
            <Grid item xs={12} sm={12}>
              <Card
                container
                sx={{ backgroundColor: "#f1f2f6" }}
                elevation={5}
                variant="outlined"
              >
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="h5" gutterBottom color="primary">
                        PRICE NEGOTIATION
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <PriceNegociationSection
                        leadId={id}
                        contactId={contactId}
                        setLoaderDialog={setLoaderDialog}
                        setUpdateLeadCalled={setUpdateLeadCalled}
                        setIactionedApiCalled={setIactionedApiCalled}
                        fromCreateProposal={true}
                        isPriceNegotiation={isPriceNegotiation}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid> /**By uncomment from 941 to 968 price negotiation will appear*/
          ) : null}
          {/* Actions */}
          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      ACTIONS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ActionSection
                      contactInfoObj={contactInfoObj}
                      action={action}
                      setAction={setAction}
                      initialActionState={initialActionState}
                      isEditPage={isEditPage}
                      loadedActions={loadedActions}
                      setLoadedActions={setLoadedActions}
                      actionType={constants.LEADS}
                      id={contactId}
                      leadId={id}
                      techOwner={lead.techOwner}
                      checked={checked}
                      setChecked={setChecked}
                      getAllDoers={() => {
                        getAllActionDoersByLeadId();
                      }}
                      currentUser={currentUser}
                      lead={lead}
                      fromLeadPage={true}
                      allDoers={allDoers}
                      iactionedApiCalled={iactionedApiCalled}
                      setIactionedApiCalled={setIactionedApiCalled}
                      storeTemplate={storeTemplate}
                      setStoreTemplate={setStoreTemplate}
                      storeCurrentTemplate={storeCurrentTemplate}
                      setStoreCurrentTemplate={setStoreCurrentTemplate}
                      setIsPriceNegotiation={setIsPriceNegotiation}
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                      state={state}
                      content={content}
                      setContent={setContent}
                      previousContent={previousContent}
                      setPreviousContent={setPreviousContent}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/*Documents  */}
          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={9} sm={9}>
                    <Typography variant="h5" gutterBottom color="primary">
                      DOCUMENTS
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <div className="file-cards">
                      <div className="file-inputs">
                        {handleButtonByRoleAccess(
                          "LEADS",
                          currentUser,
                          lead.salesOwner,
                          allDoers,
                          lead.techOwner
                        ) ? (
                          <Button
                            variant="contained"
                            size="medium"
                            startIcon={<AddIcon />}
                            onClick={handleDocClickOpen}
                          >
                            Upload
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Card elevation={3} variant="outlined">
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12}>
                            {console.log("Leads", lead)}
                            <FileList
                              fileList={lead.documents}
                              contactId={contactId}
                              leadId={id}
                              setFiles={setFiles}
                              currentUser={currentUser}
                              salesOwner={contact.salesOwner}
                              // techOwner={lead.techOwner}
                              removeFile={() => {}}
                              setUpdateLeadCalled={setUpdateLeadCalled}
                              setIactionedApiCalled={setIactionedApiCalled}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                </Grid>
                <Dialog maxWidth="md" open={docOpen} onClose={handleDocClose}>
                  <DialogContent>
                    <FileUpload
                      files={files}
                      setFiles={setFiles}
                      checked={checked}
                      setChecked={setChecked}
                      descriptionObj={descriptionObj}
                      setDescriptionObj={setDescriptionObj}
                      lead={lead}
                      currentUser={currentUser}
                      fromLeadViewPage={true}
                      isFromComment={false}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        handleDocClose();
                      }}
                      color="error"
                    >
                      CANCEL
                    </Button>
                    <Button disabled={saveButton} onClick={submitFile}>
                      SAVE
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>

          {/* comments */}
          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      COMMENTS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <CommentSection
                      comment={comment}
                      setComment={setComment}
                      setUpdateLeadCalled={setUpdateLeadCalled}
                      pageCounts={pageCount}
                      setPageCount={setPageCount}
                      counts={count}
                      leadId={id}
                      id={contactId}
                      initialCommentState={initialCommentState}
                      loadedComments={loadedComments}
                      setLoadedComments={setLoadedComments}
                      commentType={constants.LEADS}
                      fromLeadPage={true}
                      techOwner={lead.techOwner}
                      salesOwner={contact.salesOwner}
                      currentUser={currentUser}
                      lead={lead}
                      allDoers={allDoers}
                      setIactionedApiCalled={setIactionedApiCalled}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      descriptionObj={descriptionObj}
                      files={files}
                      setDescriptionObj={setDescriptionObj}
                      setFiles={setFiles}
                      setLoaderDialog={setLoaderDialog}
                    />
                  </Grid>
                </Grid>
                <Dialog maxWidth="md" open={open} onClose={handleClose}>
                  <DialogContent>
                    <FileUpload
                      files={files}
                      setFiles={setFiles}
                      checked={checked}
                      setChecked={setChecked}
                      descriptionObj={descriptionObj}
                      setDescriptionObj={setDescriptionObj}
                      lead={lead}
                      currentUser={currentUser}
                      fromLeadViewPage={true}
                      isFromComment={true}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                      color="error"
                    >
                      CANCEL
                    </Button>
                    <Button disabled={saveButton} onClick={submitDocComment}>
                      SAVE
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
          <Dialog open={loaderDialog}>
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "20px",
              }}
            >
              Loading... Please Wait
            </DialogActions>
          </Dialog>
          <Dialog open={watcherDialog} fullWidth>
            <DialogTitle>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={11.25}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <span>Watcher List:</span>
                </Grid>
                <Grid item xs={0.75}>
                  <IconButton onClick={closeWatcherDialog}>
                    <CancelIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={1} sx={{ marginTop: "2px" }}>
                <Grid item xs={isWatcherDialogOnEdit ? 10.5 : 11}>
                  <Watcher
                    isWatcherDialogOnEdit={isWatcherDialogOnEdit}
                    setIsWatcherDialogOnEdit={setIsWatcherDialogOnEdit}
                    users={users}
                    setUsers={setUsers}
                    lead={lead}
                    setLead={setLead}
                    salesOwnerTechOwnerInfo={salesOwnerTechOwnerInfo}
                    setsalesOwnerTechOwnerInfo={setsalesOwnerTechOwnerInfo}
                    currentUser={currentUser}
                    filterDone={filterDone}
                  />
                </Grid>
                <Grid
                  item
                  xs={isWatcherDialogOnEdit ? 1.5 : 1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {!isWatcherDialogOnEdit ? (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setIsWatcherDialogOnEdit(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        color="error"
                        onClick={() => {
                          setIsWatcherDialogOnEdit(false);
                          const temp = { ...lead };
                          temp.watchers = TempLead.watchers;
                          setLead(temp);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton color="primary" onClick={updateWatchers}>
                        <SaveIcon />
                      </IconButton>
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingRight: "20px", paddingBottom: "10px" }}>
              {/* {<Button color="primary" variant="contained" onClick={updateWatchers}>SAVE</Button>} */}
            </DialogActions>
          </Dialog>
        </Grid>
        <Dialog
          open={leadMailDialog}
          sx={{ position: "absolute", left: 10, right: 50 }}
        >
          <DialogTitle
            sx={{
              marginRight: 0,
              paddingRight: "4px",
              paddingTop: "4px",
              paddingLeft: "4px",
            }}
          >
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "right",
                }}
              >
                <IconButton color="primary" onClick={closeHandleLeadMail}>
                  <CancelIcon color="#2065D1" />
                </IconButton>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                All mails from:{" "}
                <span style={{ color: "#2065D1" }}>{contact.name} </span>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ marginTop: "12px" }} />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={{ width: "400px" }}>
            <Grid container spacing={1}>
              {contactMails.length !== 0 ? (
                contactMails.map((element, index1) => {
                  //array of email object
                  console.log(`${TAG}.element.index`, element, index1);
                  return (
                    <>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "left" }}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handelEmailView(index1);
                          }}
                        >
                          <span style={{ color: "#2065D1" }}>Sub: </span>
                          {element.subject}
                        </span>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-country-select-label">
                            Lead
                          </InputLabel>
                          <Select
                            label="Lead"
                            name="leadId"
                            value={selectData[element.hash.toString()]}
                            // value={selectData}
                            onChange={(e) => {
                              handleChange(e, index1, element.hash);
                            }}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {leadData.map((newLead, index2) => {
                              if (index2 === 0) {
                                console.log(
                                  "element.hash.toString",
                                  element.hash.toString()
                                );
                                console.log(
                                  "element.hash.toString",
                                  selectData
                                );
                              }
                              return (
                                <MenuItem key={newLead._id} value={newLead._id}>
                                  {newLead.summary}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <Tooltip title="Associate">
                          <IconButton
                            color="success"
                            onClick={() => {
                              handleAssociate(contactId, index1);
                            }}
                          >
                            <CheckIcon color="success" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Discard">
                          <IconButton
                            color="error"
                            onClick={() => {
                              handleDisCard(contactId, index1);
                            }}
                          >
                            <ClearIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: "8px" }}>
                        {index1 === contactMails.length - 1 ? null : (
                          <Divider />
                        )}
                      </Grid>
                    </>
                  );
                })
              ) : (
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  {/* <Typography>No Mails From This Contact</Typography >  */}
                  <span span style={{ textAlign: "center", color: "red" }}>
                    No Mails From This Contact
                  </span>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog open={isLoading}>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingBottom: "20px",
            }}
          >
            Loading... Please Wait
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={openEmail}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {/* {"Use Google's location service?"} */}
            <IconButton
              aria-label="close"
              onClick={handleCloseEmailView}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid item xs={12}>
                <FormControl>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div dangerouslySetInnerHTML={sanitizedData()} />
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog open={asscoateEmailListDialog.state} onClose={handleClose}>
          <DialogTitle textAlign="center">
            By Clicking on Private The Documents Will Be Uploaded As Private
          </DialogTitle>
          <DialogContent>
            <AssociateEmailPrivate
              allFiles={asscoateEmailListDialog.attachments}
              setAssociateEmailListDialog={setAssociateEmailListDialog}
              descriptionObj={descriptionObj}
              setDescriptionObj={setDescriptionObj}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              color="error"
            >
              CANCEL
            </Button>
            <Button
              disabled={saveButton}
              onClick={(e) => emailDocsUpload(e, asscoateEmailListDialog)}
            >
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default LeadViewPage;
